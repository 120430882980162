import React from 'react'

import Column from '@components/common/Column'
import Row from '@components/common/Row'

import VoteHeader from './FixedVoteTop'

interface VoteLayoutProps {
  children?: React.ReactNode
  header?: React.ReactNode
  hideHeader?: boolean
  footer?: React.ReactNode
  hideFooter?: boolean
}

const VoteLayout: React.FC<VoteLayoutProps> = props => {
  const {
    children,
    header = null,
    hideHeader = false,
    footer = null,
    hideFooter = false
  } = props

  return (
    <Column className="h-full w-full">
      {!hideHeader && header != null && header}
      {!hideHeader && header == null && <VoteHeader />}
      <Column className="h-full overflow-y-scroll px-[24px]">{children}</Column>
      {!hideFooter && footer != null && footer}
      {hideFooter && (
        <>
          <Row className="fixed bottom-0 h-[24px] w-full bg-white" />
          <Row className="h-[24px] shrink-0 bg-white" />
        </>
      )}
    </Column>
  )
}

export default VoteLayout
