import React from 'react'

import commonApi from '@api/commonApi'
import { useMutation } from 'react-query'

const MAX_FILE_SIZE = 10 * 1000 * 1000

interface MediaFileInterface {
  id: number
  file: string
  type: string
  url: string
}

interface FileInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  children?: React.ReactNode
  onChangeFile: (file: MediaFileInterface) => void
}

const FileInput = React.forwardRef<HTMLInputElement, FileInputProps>(
  (props, ref) => {
    const { children, onChangeFile, ..._props } = props
    // const fileRef = useRef<HTMLInputElement>(null)
    // React.useImperativeHandle(ref, () => ({
    //   ...(fileRef.current as HTMLInputElement)
    // }))

    const mutation = useMutation(commonApi.postMediaImage, {
      onSuccess: data => {
        onChangeFile(data.data.data)
      }
    })

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0]

      if (file != null) {
        const reader = new FileReader()
        reader.onload = () => {
          if (file.size < MAX_FILE_SIZE) {
            const image = reader.result as string
            mutation.mutate({ data: { file: image } })
          } else {
            alert('File size is too large')
          }
        }
        reader.readAsDataURL(file)
      }
    }

    return (
      <>
        <input
          {..._props}
          ref={ref}
          type="file"
          className="sr-only"
          accept="image/*"
          onChange={handleFileChange}
        />
        {children}
      </>
    )
  }
)

FileInput.displayName = 'FileInput'

export default FileInput

// import React, { useRef } from 'react'

// const MAX_FILE_SIZE = 10 * 1000 * 1000

// interface FileInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
//   type: 'image' | 'video'
// }

// const FileInput: React.FC<FileInputProps> = props => {
//   const { type, ..._props } = props
//   const fileRef = useRef<HTMLInputElement>(null)
//   console.log(type)

//   const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     const file = e.target.files?.[0]

//     if (file != null) {
//       const reader = new FileReader()
//       reader.onload = () => {
//         if (file.size < MAX_FILE_SIZE) {
//           const image = reader.result as string
//           console.log(image)
//           // if (setImage != null) {
//           //   setImage(image)
//           // }
//         } else {
//           alert('File size is too large')
//         }
//       }
//       reader.readAsDataURL(file)
//     }
//   }

//   return (
//     <input
//       {..._props}
//       ref={fileRef}
//       type="file"
//       className="sr-only"
//       accept="image/*"
//       onChange={handleFileChange}
//     />
//   )
// }

// export default FileInput
