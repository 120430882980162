import React from 'react'

interface ProgressBarProps {
  rate: number
}

const ProgressBar: React.FC<ProgressBarProps> = props => {
  const { rate } = props
  return (
    <div className="flex h-[8px] grow overflow-hidden rounded-full bg-progress-none">
      <div
        className="flex bg-gradient-to-r from-progress-from to-progress-to"
        style={{ width: `${rate}%` }}
      />
    </div>
  )
}

export default ProgressBar
