import React from 'react'

import styled from '@emotion/styled'

interface CoverFitImageProps
  extends React.ImgHTMLAttributes<HTMLImageElement> {}

const CoverFitImage: React.FC<CoverFitImageProps> = props => {
  const { className = '', ...restProps } = props
  return <StyledImage className={className} {...restProps} />
}

export default CoverFitImage

const StyledImage = styled.img`
  object-fit: cover;
`
