import React from 'react'

import styled from '@emotion/styled'
import { Option } from 'types'

import RadioOption from './RadioOption'

interface RadioGroupProps {
  optionList: Option[]
  groupName: string
  selectedOption: Option
  onChange: (option: Option) => void
  className?: string
}

const RadioGroup: React.FC<RadioGroupProps> = props => {
  const {
    optionList,
    groupName,
    selectedOption,
    onChange,
    className = '',
    ...restProps
  } = props

  return (
    <StyledRadioGroup className={className} {...restProps}>
      {optionList.map(option => (
        <RadioOption
          key={option.value}
          isSelected={selectedOption.value === option.value}
          option={option}
          groupName={groupName}
          onChange={() => {
            onChange(option)
          }}
        />
      ))}
    </StyledRadioGroup>
  )
}

export default RadioGroup

const StyledRadioGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 28px;
`
