import { PostMessagePath, RequestCommand } from 'types/postMessageCommand'

/**
 * 부모 윈도우에 postMessage 보내는 훅
 */
const usePostMessage = () => {
  const postMessage = (command: RequestCommand, data?: any) => {
    // console.log('투표. 에디터로 전송', command)
    window?.parent?.postMessage?.(
      {
        from: PostMessagePath.Vote,
        command,
        ...data
      },
      '*'
    )
  }

  return postMessage
}

export default usePostMessage
