import React from 'react'

import Row from '@components/common/Row'

interface VoteTitleProps {
  voteTitle: string
}

const VoteTitle: React.FC<VoteTitleProps> = props => {
  const { voteTitle } = props

  return (
    <Row>
      <span className="text-[18px] font-semibold leading-[26px] tracking-[-0.36px] text-black-200">
        {voteTitle}
      </span>
    </Row>
  )
}

export default VoteTitle
