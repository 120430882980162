import React, { Dispatch, SetStateAction } from 'react'

import Button from '@components/button/Button'
import Row from '@components/common/Row'
import { Dayjs } from 'dayjs'

import SelectorLayout from './SelectorLayout'

interface YearSelectorProps {
  now: Dayjs
  uiDate: Dayjs
  setUiDate: Dispatch<SetStateAction<Dayjs>>
  disabledBeforeDate: boolean
  close: () => void
}

const YearSelector: React.FC<YearSelectorProps> = props => {
  const {
    //
    now,
    uiDate,
    setUiDate,
    disabledBeforeDate,
    close
  } = props
  return (
    <SelectorLayout close={close}>
      <div className="pointer-events-none absolute inset-x-0 top-0 h-[30px] bg-gradient-to-b from-white to-white/[0.2]" />
      <div className="pointer-events-none absolute inset-x-0 bottom-[20px] h-[30px] bg-gradient-to-t from-white to-white/[0.2]" />
      <Row className="h-[160px] grow flex-wrap justify-between gap-y-[4px] overflow-y-auto  pb-[30px]">
        {Array.from({ length: 100 }).map((_, index) => {
          const year = index + 2001
          const disabled = disabledBeforeDate && year < now.year()

          // uiDate의 year와 year가 같으면 true
          const isSameYear = uiDate.year() === year

          return (
            <Button
              key={index}
              className={`group/dp_year_btn flex h-[30px] w-[70px] items-center justify-center rounded-full
                        ${
                          !disabled
                            ? `${
                                isSameYear
                                  ? 'bg-primary-100 hover:bg-primary-200'
                                  : 'hover:bg-gray-200'
                              }`
                            : ''
                        }`}
              disabled={disabled}
              onClick={() => {
                setUiDate(prev => prev.year(year))
                close()
              }}
            >
              <span
                className={`text-[13px] leading-[1.54] tracking-[-0.26px] text-black-200 group-disabled/dp_year_btn:text-gray-500 ${
                  isSameYear ? 'text-white' : ''
                }`}
              >
                {year}
              </span>
            </Button>
          )
        })}
      </Row>
    </SelectorLayout>
  )
}

export default YearSelector
