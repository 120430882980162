import React from 'react'

import Column from '@components/common/Column'
import Row from '@components/common/Row'
import CoverFitImage from '@components/image/CoverFitImage'
import dayjs from 'dayjs'
import { useVoteQuery } from 'hooks/query/useVoteQuery'
import { useParams } from 'react-router-dom'
import { DateFormatterType, dateFormatter } from 'utils/utils'

// interface VotersProps {}

const Voters: React.FC = () => {
  const { voteId } = useParams<{ voteId: string }>()

  const { data, isLoading } = useVoteQuery(voteId ?? '')
  const voters = data?.data?.voters

  if (isLoading || voters == null) return <div />

  return (
    <Column className="h-full w-[320px] gap-[8px] overflow-y-auto px-[20px] pb-[20px]">
      {voters.map(voter => (
        <Row key={voter.user.id} className="items-center">
          <CoverFitImage
            src={voter.user.profile_image_url}
            className="h-[36px] w-[36px] rounded-full border border-white"
          />
          <div className="ml-[8px] grow">{voter.user.username}</div>

          <span className="text-[13px] leading-[1.38] tracking-[-0.26px] text-gray-600">
            {dateFormatter(dayjs(voter.created), DateFormatterType.Normal)}
          </span>
        </Row>
      ))}
    </Column>
  )
}

export default Voters
