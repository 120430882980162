import React, { useRef } from 'react'

import { VoteInterface } from '@api/voteApi'
import JButton from '@components/button/JButton'
import Column from '@components/common/Column'
import Space from '@components/common/Space'
import FixedVoteBottomLayout from '@components/vote/FixedVoteBottomLayout'
import { contextState } from '@store/atoms/contextState'
import dayjs from 'dayjs'
import useMeasureHeight from 'hooks/useMeasureHeight'
import usePostMessage from 'hooks/usePostMessage'
import { useRecoilValue } from 'recoil'
import { RequestCommand } from 'types/postMessageCommand'

import VoteDivider from './components/VoteDivider'
import VoteEndIn from './components/VoteEndIn'
import VoteOption from './components/VoteOption/VoteOption'
import Voters from './components/Voters'
import VoteSettingSummary from './components/VoteSettingSummary'
import VoteTitle from './components/VoteTitle'
import VoteLayout from '../../components/vote/VoteLayout'

interface VotePreviewProps {
  vote: VoteInterface
}

/**
 * 클럽 투표 미리보기에서 사용하는 컴포넌트
 */
const VotePreview: React.FC<VotePreviewProps> = props => {
  const { vote } = props
  const contextData = useRecoilValue(contextState)

  const isVoted = vote.is_voted
  const isEnded = vote.status === 'ENDED'
  const showProgressing =
    vote.view_result === 'ALWAYS' ||
    (vote.view_result === 'INSTANT' && (isVoted || isEnded)) ||
    (vote.view_result === 'CLOSED' && isEnded)
  const isPosted =
    vote.channel_url != null || vote.club_id != null || vote.forum_id != null

  const postMessage = usePostMessage()

  const ref = useRef<HTMLDivElement>(null)

  // 레이아웃의 높이를 계산하여 부모 window에게 전달한다.
  useMeasureHeight(ref, height => {
    postMessage(RequestCommand.MeasureHeightSuccess, { data: { height } })
  })

  const onClickOptionImage = (source: string) => {
    const imageSourceList = vote.options
      .map(option => option.image_url ?? '')
      .filter(src => src !== '')

    postMessage(RequestCommand.ClickVoteOptionImage, {
      data: {
        index: imageSourceList.findIndex(src => src === source) ?? 0,
        sourceList: imageSourceList,
        onPreview: true
      }
    })
  }

  const onClickVoteButton = () => {
    postMessage(RequestCommand.ClickVoteOnPreview, {
      data: { voteId: vote.id }
    })
  }

  const voteFooter = (
    <FixedVoteBottomLayout>
      <div />

      <JButton
        size="lg"
        // label={isEnded ? 'Confirm' : isVoted ? 'Vote again' : 'Vote'}
        label={'Vote'}
        disabled={!isPosted}
        className="w-[160px]"
        onClick={onClickVoteButton}
      />
    </FixedVoteBottomLayout>
  )

  return (
    <div ref={ref}>
      <VoteLayout
        footer={voteFooter}
        hideHeader={vote.channel_url != null}
        hideFooter={contextData?.isEditMode}
      >
        <VoteTitle voteTitle={vote?.title} />
        <Space height={8} />
        <VoteSettingSummary vote={vote} />
        <Space height={20} />
        <Column className="gap-[24px]">
          {vote.options.map(option => {
            return (
              <VoteOption
                key={option.id}
                option={option}
                isVoted={isVoted}
                isEnded={isEnded}
                showProgressing={showProgressing}
                onClickImage={() => {
                  if (option.image_url != null) {
                    onClickOptionImage(option.image_url)
                  }
                }}
                isMostVoted={vote.most_voted_options?.includes?.(option.id)}
              />
            )
          })}
        </Column>
        <Space height={24} />
        {vote.expiry_date != null && !isEnded && (
          <>
            <VoteDivider />
            <Space height={24} />
            <VoteEndIn isEnded={isEnded} expiryDate={dayjs(vote.expiry_date)} />
            <Space height={24} />
          </>
        )}
        {!vote.is_secret && vote.voters != null && vote.vote_count > 0 && (
          <>
            <VoteDivider />
            <Space height={24} />
            <Voters
              voteCount={vote.vote_count}
              voters={vote.voters}
              // onClickMore={() => {
              //   postMessage(RequestCommand.ClickVoters, { voters: vote.voters })
              // }}
            />
            <Space height={24} />
          </>
        )}
        {/* {isMine && !isEnded && (
        <>
          <Row className="justify-end">
            <JButton
              label="Stop Vote"
              color="default"
              size="sm"
              onClick={onClickStop}
            />
          </Row>
          <Space height={24} />
        </>
      )} */}
      </VoteLayout>
    </div>
  )
}

export default VotePreview
