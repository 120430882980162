import React from 'react'

import Button from '@components/button/Button'
import { ReactComponent as CalendarIcon } from '@icons/ic-calendar.svg'
import { Dayjs } from 'dayjs'

interface DatePickerInputProps {
  selectedDate: Dayjs
  setIsOpen: (isOpen: boolean) => void
  isOpen: boolean
}

const DatePickerInput: React.FC<DatePickerInputProps> = props => {
  const { selectedDate, setIsOpen, isOpen } = props
  return (
    <Button
      className="group/dp_open_btn flex h-[52px] items-center justify-between rounded-[8px] border border-gray-300 bg-white pl-[16px] pr-[14px]"
      onClick={() => {
        setIsOpen(!isOpen)
      }}
    >
      <span className="text-[16px] font-medium leading-[1.5] tracking-[-0.32px] text-black-100">
        {selectedDate.format('YYYY-MM-DD')}
      </span>

      <CalendarIcon
        className={`${
          isOpen
            ? 'fill-primary-100'
            : 'fill-gray-500 group-hover/dp_open_btn:fill-black-200'
        }`}
      />
    </Button>
  )
}

export default DatePickerInput
