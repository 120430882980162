import React, { Dispatch, SetStateAction, useMemo } from 'react'

import Button from '@components/button/Button'
import Row from '@components/common/Row'
import { ReactComponent as ArrowRightIcon } from '@icons/ic-arrow-right.svg'
import { Dayjs } from 'dayjs'

import { MonthOrYear } from './DatePicker'

interface CalendarHeaderProps {
  uiDate: Dayjs
  setUiDate: Dispatch<SetStateAction<Dayjs>>
  now: Dayjs
  disabledBeforeDate: boolean
  setIsOpenMonthOrYear: Dispatch<SetStateAction<MonthOrYear>>
}

const CalendarHeader: React.FC<CalendarHeaderProps> = props => {
  const {
    //
    uiDate,
    setUiDate,
    now,
    disabledBeforeDate,
    setIsOpenMonthOrYear
  } = props

  const prevButtonDisabled = useMemo(() => {
    return disabledBeforeDate && uiDate.isSameOrBefore(now, 'month')
  }, [disabledBeforeDate, uiDate, now])

  return (
    <Row className="grow items-center justify-between">
      <Button
        className="group/dp_prev_btn rounded-[4px] hover:bg-gray-200 disabled:bg-transparent"
        disabled={prevButtonDisabled}
        onClick={() => {
          setUiDate(prev => prev.subtract(1, 'month'))
        }}
      >
        <ArrowRightIcon className="h-[32px] w-[32px] rotate-180 group-disabled/dp_prev_btn:fill-gray-500" />
      </Button>

      <Row className="items-center">
        <Button
          className="rounded-[4px] px-[6px] py-[4px] hover:bg-gray-200"
          onClick={() => {
            setIsOpenMonthOrYear(prev => (prev === 'month' ? null : 'month'))
          }}
        >
          <span className="font-medium">{uiDate.format('MMMM')}</span>
        </Button>
        <Button
          className="rounded-[4px] px-[6px] py-[4px] hover:bg-gray-200"
          onClick={() => {
            setIsOpenMonthOrYear(prev => (prev === 'year' ? null : 'year'))
          }}
        >
          <span className="font-medium">{uiDate.format('YYYY')}</span>
        </Button>
      </Row>

      <Button
        className="rounded-[4px] hover:bg-gray-200"
        onClick={() => {
          setUiDate(prev => prev.add(1, 'month'))
        }}
      >
        <ArrowRightIcon className="h-[32px] w-[32px]" />
      </Button>
    </Row>
  )
}

export default CalendarHeader
