import React from 'react'

import Row from '@components/common/Row'
import Space from '@components/common/Space'
import { Dayjs } from 'dayjs'

import Timer from './Timer'

interface VoteEndInProps {
  expiryDate: Dayjs
  isEnded?: boolean
}

const VoteEndIn: React.FC<VoteEndInProps> = props => {
  const { expiryDate, isEnded = false } = props

  return (
    <>
      <Row className="items-end justify-between">
        <span className="text-[18px] font-semibold leading-[26px] tracking-[-0.36px] text-black-200">
          Ends in
        </span>
        <span className="text-[14px] leading-[20px] tracking-[-0.28px] text-gray-400">
          {expiryDate.format('MMM DD, YYYY [at] hh:mm')}
        </span>
      </Row>
      <Space height={12} />
      <Timer expiryDate={expiryDate} timedOut={isEnded} />
    </>
  )
}

export default VoteEndIn
