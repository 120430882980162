import React from 'react'

import styled from '@emotion/styled'
import { ReactComponent as PlusIcon } from '@icons/ic-plus-sm.svg'

interface AddAnswerButtonProps
  extends React.HTMLAttributes<HTMLButtonElement> {}

const AddAnswerButton: React.FC<AddAnswerButtonProps> = props => {
  const { className = '', ...restProps } = props
  return (
    <StyledButton className={className} {...restProps}>
      <PlusIcon />
      <StyledSpan>Add answers</StyledSpan>
    </StyledButton>
  )
}

export default AddAnswerButton

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  border: 1px solid rgb(189 189 189);
  background-color: rgb(248 248 248);
  padding: 12px 16px 12px 12px;

  :hover {
    background-color: rgb(238 238 238);
    svg {
      fill: #242a3c;
    }
  }

  :active {
    background-color: rgb(224 224 224);
  }
`

const StyledSpan = styled.span`
  font-size: 15px;
  line-height: 1.47;
  letter-spacing: -0.3px;
  color: rgb(36 42 60);
`
