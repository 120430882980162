import React from 'react'

import { useVoteQuery } from 'hooks/query/useVoteQuery'
import { Route, Routes, useParams } from 'react-router-dom'

import PostedVote from './PostedVote'
import VotePreview from './VotePreview'

const Vote: React.FC = () => {
  const { voteId } = useParams()

  const { data, isLoading, isError } = useVoteQuery(voteId ?? '')

  const vote = data?.data

  if (isLoading) return <div />
  if (vote == null) return <div />
  if (isError) return <div />

  return (
    <Routes>
      <Route path="/" element={<VotePreview vote={vote} />} />
      <Route path="/club" element={<PostedVote vote={vote} />} />
      <Route path="/chat" element={<PostedVote vote={vote} />} />
      <Route path="/forum" element={<PostedVote vote={vote} />} />
    </Routes>
  )
}

export default Vote
