// useVoteQuery.ts
import voteApi, { DjangoInterface, VoteInterface } from '@api/voteApi'
import { UseQueryOptions, useQuery } from 'react-query'
import { queryKeys } from 'types/queryKeys'

export const useVoteQuery = (
  voteId: string | number,
  options?: UseQueryOptions<DjangoInterface<VoteInterface>>
) => {
  const voteQuery = useQuery<DjangoInterface<VoteInterface>>(
    [queryKeys.vote, voteId],
    async () => {
      const response = await voteApi.getVote({ id: Number(voteId) })
      /**
       * @TODO options는 required이기 때문에 아래 로직 제거
       */
      return {
        ...response.data,
        data: {
          ...response.data.data,
          options: response.data.data.options ?? []
        }
      }
    },
    { enabled: Boolean(voteId), ...options }
  )

  return voteQuery
}
