import React from 'react'

import Row from '@components/common/Row'

const VoteHeader: React.FC = () => {
  return (
    <Row className="h-[72px] bg-white px-[24px] py-[20px]">
      <span className="text-[22px] font-semibold leading-[30px] tracking-[-0.44px] text-black-200">
        Poll
      </span>
    </Row>
  )
}
export default VoteHeader

// const VoteHeader: React.FC = () => {
//   return (
//     <>
//       <Row className="fixed left-[1px] top-0 z-20 h-[72px] w-[598px] bg-white px-[24px] py-[20px]">
//         <span className="text-[22px] font-semibold leading-[30px] tracking-[-0.44px] text-black-200">
//           Poll
//         </span>
//       </Row>
//       <Row className="flex h-[72px]" />
//     </>
//   )
// }
// export default VoteHeader
