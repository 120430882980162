import React from 'react'

import Button, { ButtonProps } from './Button'

export type ButtonSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl'

export interface SizedButtonProps extends ButtonProps {
  size?: ButtonSize
}

const SizedButton: React.FC<SizedButtonProps> = props => {
  const { className = '', children, size = 'md', ..._props } = props

  return (
    <Button
      {..._props}
      className={`${
        size === 'xs'
          ? 'h-[36px] rounded-[6px] px-[10px]'
          : size === 'sm'
          ? 'h-[40px] rounded-[6px] px-[10px]'
          : size === 'md'
          ? 'h-[48px] rounded-[8px] px-[14px]'
          : size === 'lg'
          ? 'h-[52px] rounded-[10px] px-[16px]'
          : size === 'xl'
          ? 'h-[56px] rounded-[12px] px-[20px]'
          : ''
      } items-center justify-center ${className}`}
    >
      {children}
    </Button>
  )
}

export default SizedButton
