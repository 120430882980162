import React from 'react'

import Button from '@components/button/Button'
import { ReactComponent as TimeIcon } from '@icons/ic-time.svg'
import { Dayjs } from 'dayjs'

interface TimePickerInputProps {
  selectedDate: Dayjs
  setIsOpen: (isOpen: boolean) => void
  isOpen: boolean
  validError: boolean
}

const TimePickerInput: React.FC<TimePickerInputProps> = props => {
  const { selectedDate, setIsOpen, isOpen, validError } = props
  return (
    <Button
      className={`group/tp_open_btn flex h-[52px] items-center justify-between rounded-[8px] border-gray-300 bg-white pl-[16px] pr-[14px] ${
        validError ? 'border border-error-100' : 'border'
      }`}
      onClick={() => {
        setIsOpen(!isOpen)
      }}
    >
      <span className="text-[16px] font-medium leading-[1.5] tracking-[-0.32px] text-black-100">
        {selectedDate.format('h : mm A')}
      </span>

      <TimeIcon
        className={`${
          isOpen
            ? 'fill-primary-100'
            : 'fill-gray-500 group-hover/tp_open_btn:fill-black-200'
        }`}
      />
    </Button>
  )
}

export default TimePickerInput
