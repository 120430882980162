import React, { useMemo } from 'react'

import { VoteInterface } from '@api/voteApi'
import dayjs from 'dayjs'

interface VoteSettingSummaryProps {
  vote: VoteInterface
  settingButton?: React.ReactNode
}

const VoteSettingSummary: React.FC<VoteSettingSummaryProps> = props => {
  const { vote, settingButton } = props

  const voteSettingSummary: string = useMemo(() => {
    const list: string[] = []
    if (vote == null) return ''

    if (vote.status === 'ENDED' && vote.ended_at != null)
      return `Ended ${dayjs(vote.ended_at).format('MMM D, YYYY [at] HH:mm')}`
    if (vote.is_secret) list.push('Secret Poll')

    switch (true) {
      case vote.max_options === 1:
        break
      case vote.max_options === vote.options.length:
        list.push('All Selectable')
        break
      default:
        list.push(`Up to ${vote.max_options} Selectable`)
        break
    }

    return list.join(' • ')
  }, [vote])

  return (
    <span>
      {voteSettingSummary !== '' && (
        <span className="mr-[4px] text-[13px] leading-[18px] tracking-[-0.26px] text-gray-500">
          {voteSettingSummary}
        </span>
      )}

      {settingButton ?? settingButton}
    </span>
  )
}

export default VoteSettingSummary
