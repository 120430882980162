import React from 'react'

import CoverFitImage from '@components/image/CoverFitImage'
import classNames from 'classnames'

interface VoteOptionImageProps {
  src: string
  onClick?: () => void
  disabled?: boolean
  size?: 'small' | 'large'
}

const VoteOptionImage: React.FC<VoteOptionImageProps> = props => {
  const { src, onClick, disabled = false, size = 'large' } = props

  return (
    <CoverFitImage
      className={classNames(
        'rounded-[8px]',
        size === 'small' ? 'h-[40px] w-[40px]' : 'h-[48px] w-[48px]',
        { 'cursor-pointer': !disabled }
      )}
      src={src}
      alt="vote_option_image"
      onClick={disabled ? undefined : onClick}
    />
  )
}

export default VoteOptionImage
