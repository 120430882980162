import React from 'react'

import Row from '@components/common/Row'
import Switch from '@components/switch/Switch'

import VoteLabel from './VoteLabel'

interface VoteLabelWithSwitchProps {
  label: string
  checked: boolean
  onChange: (checked: boolean) => void
  disabled?: boolean
}

const VoteLabelWithSwitch: React.FC<VoteLabelWithSwitchProps> = props => {
  const { label, checked, onChange, disabled } = props
  return (
    <Row className="items-center justify-between">
      <VoteLabel>{label}</VoteLabel>

      <Switch checked={checked} onChange={onChange} disabled={disabled} />
    </Row>
  )
}

export default VoteLabelWithSwitch
