import React from 'react'

import styled from '@emotion/styled'

interface VoteLabelProps {
  children: string
  className?: string
}

const VoteLabel: React.FC<VoteLabelProps> = props => {
  const { children, className = '' } = props
  return <StyledLabel className={className}>{children}</StyledLabel>
}

export default VoteLabel

const StyledLabel = styled.span`
  font-size: 18px;
  font-weight: 600;
  line-height: 1.44;
  letter-spacing: -0.36px;
  color: rgb(18 18 18);
`
