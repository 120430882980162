import React from 'react'

import { ReactComponent as CheckIcon } from '@assets/icons/ic-check.svg'
import Button from '@components/button/Button'
import Row from '@components/common/Row'
import { Option } from 'types'

interface OrderingTabsProps {
  optionList: Option[]
  selectedOption: Option
  onChange: (option: Option) => void
}

const OrderingTabs: React.FC<OrderingTabsProps> = props => {
  const { optionList, selectedOption, onChange } = props

  return (
    <Row className="gap-[12px]">
      {optionList.map((option, index) => {
        const isActive = option.value === selectedOption.value
        const onClick = () => {
          onChange(option)
        }

        return (
          <Button
            key={index}
            onClick={onClick}
            className="items-center gap-[2px]"
          >
            <CheckIcon
              className={`h-[16px] w-[16px] transition-all ${
                isActive
                  ? 'fill-black-200 stroke-black-200'
                  : 'fill-transparent stroke-transparent'
              }`}
            />
            <span
              className={`text-[14px] font-medium leading-[1.43] tracking-[-0.28px] transition-all ${
                isActive
                  ? 'text-black-100'
                  : 'text-gray-600 hover:text-gray-700'
              }`}
            >
              {option.label}
            </span>
          </Button>
        )
      })}
    </Row>
  )
}

export default OrderingTabs
