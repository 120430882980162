import React from 'react'

import Row from '@components/common/Row'
import styled from '@emotion/styled'

interface FixedVoteBottomLayoutProps {
  children?: React.ReactNode
}

const FixedVoteBottomLayout: React.FC<FixedVoteBottomLayoutProps> = props => {
  const { children } = props

  return <StyledRow>{children}</StyledRow>
}

export default FixedVoteBottomLayout

const StyledRow = styled(Row)`
  width: 100%;
  justify-content: space-between;
  padding: 20px 24px;
  bottom: 0px;
  background-color: rgb(255 255 255);

  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border-top-width: 1px;
  border-top-color: rgb(224 224 224);
`
