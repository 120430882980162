import React, { useMemo, useState } from 'react'

import Button from '@components/button/Button'
import Column from '@components/common/Column'
import Row from '@components/common/Row'
import dayjs, { Dayjs } from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import { useOnClickOutside } from 'hooks/useOnClickOutside'

import CalendarHeader from './CalendarHeader'
import DatePickerInput from './DatePickerInput'
import MonthSelector from './MonthSelector'
import YearSelector from './YearSelector'

dayjs.extend(isSameOrBefore)

interface DatePickerProps {
  date: Dayjs
  onChange: (date: Dayjs) => void
}

export type MonthOrYear = 'month' | 'year' | null

const DatePicker: React.FC<DatePickerProps> = props => {
  const now: Dayjs = useMemo(() => dayjs(), [])
  const { date: selectedDate = now, onChange: setSelectedDate } = props
  // 나중에 props로 받아서 사용할 수 있도록 수정
  const disabledBeforeDate = useMemo(() => true, [])

  const { isOpen, setIsOpen, ref } = useOnClickOutside()

  const [uiDate, setUiDate] = useState<Dayjs>(selectedDate)

  const [isOpenMonthOrYear, setIsOpenMonthOrYear] = useState<MonthOrYear>(null)

  const calendarData = useMemo(() => {
    const firstDayOfMonth = uiDate.date(1)
    const daysInMonth = uiDate.daysInMonth()
    const _calendarData: Dayjs[][] = []

    let week: Dayjs[] = Array(firstDayOfMonth.day()).fill(null)
    for (let day = 1; day <= daysInMonth; day++) {
      week.push(uiDate.date(day))
      if (week.length === 7) {
        _calendarData.push(week)
        week = []
      }
    }
    if (week.length > 0) {
      _calendarData.push(week)
    }

    return _calendarData
  }, [uiDate])

  return (
    <div ref={ref} className="relative flex w-full flex-col">
      <DatePickerInput
        selectedDate={selectedDate}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
      />

      {isOpen && (
        <Row className="absolute left-0 top-[58px] z-10 w-[340px] ">
          <Column className="relative w-full overflow-hidden rounded-[8px] border border-gray-600 bg-white px-[20px] py-[16px] shadow-[0_2px_6px_0_rgba(0,0,0,0.2)]">
            {/* PREV, MONTH, YEAR, NEXT */}
            <CalendarHeader
              now={now}
              uiDate={uiDate}
              setUiDate={setUiDate}
              disabledBeforeDate={disabledBeforeDate}
              setIsOpenMonthOrYear={setIsOpenMonthOrYear}
            />

            {/* 달 선택하는거 */}
            {isOpenMonthOrYear === 'month' && (
              <MonthSelector
                now={now}
                uiDate={uiDate}
                setUiDate={setUiDate}
                disabledBeforeDate={disabledBeforeDate}
                close={() => {
                  setIsOpenMonthOrYear(null)
                }}
              />
            )}

            {/* 년도 선택하는거 */}
            {isOpenMonthOrYear === 'year' && (
              <YearSelector
                now={now}
                uiDate={uiDate}
                setUiDate={setUiDate}
                disabledBeforeDate={disabledBeforeDate}
                close={() => {
                  setIsOpenMonthOrYear(null)
                }}
              />
            )}

            {/* 일 월 화 수 목 금 토 일 */}
            {<WeekRows />}

            {/* 1일 ~ 말일 */}
            <Column className="mt-[12px] gap-[8px]">
              {calendarData.map((week, index) => (
                <Row key={index} className="items-center gap-[8px]">
                  {week.map((date, index) => {
                    const isSunday = index === 0
                    const isBlank = date === null
                    const isSelected =
                      date?.isSame(selectedDate, 'day') ?? false
                    const disabled =
                      isBlank ||
                      ((disabledBeforeDate && date?.isBefore(now, 'day')) ??
                        false)

                    return (
                      <Button
                        key={index}
                        className={`group/dp_date_btn flex h-[36px] w-[36px] items-center justify-center rounded-full disabled:bg-transparent
                      ${
                        isBlank
                          ? 'bg-transparent'
                          : `${
                              isSelected
                                ? 'bg-primary-100 hover:bg-primary-200'
                                : 'bg-white hover:bg-gray-200'
                            }`
                      }`}
                        disabled={disabled}
                        onClick={() => {
                          if (!isBlank) {
                            setSelectedDate(date)
                            setIsOpen(false)
                          }
                        }}
                      >
                        <span
                          className={`text-center text-[16px] font-medium leading-[1.25] tracking-[-0.32px] group-disabled/dp_date_btn:text-gray-500
                          ${
                            isSelected
                              ? 'text-white'
                              : `${
                                  isSunday ? 'text-error-100' : 'text-black-200'
                                }`
                          }
                        `}
                        >
                          {date?.format('D')}
                        </span>
                      </Button>
                    )
                  })}
                </Row>
              ))}
            </Column>
          </Column>
        </Row>
      )}
    </div>
  )
}

export default DatePicker

function WeekRows() {
  return (
    <Row className="mt-[16px] h-[28px] items-center gap-[8px]">
      {['S', 'M', 'T', 'W', 'T', 'F', 'S'].map((dayLabel, index) => (
        <span
          key={index}
          className="w-[36px] text-center text-[14px] font-medium leading-[1.29] tracking-[-0.28px] text-gray-500"
        >
          {dayLabel}
        </span>
      ))}
    </Row>
  )
}
