import React, { useEffect, useMemo, useState } from 'react'

import voteApi, { VoteInterface, VoteViewResult } from '@api/voteApi'
import JButton from '@components/button/JButton'
import Column from '@components/common/Column'
import DividedLine from '@components/common/DividedLine'
import Row from '@components/common/Row'
import Space from '@components/common/Space'
import DatePicker from '@components/date-picker/DatePicker'
import RadioGroup from '@components/radio/RadioGroup'
import TimePicker from '@components/time-picker/TimePicker'
import FixedVoteBottomLayout from '@components/vote/FixedVoteBottomLayout'
import VoteLayout from '@components/vote/VoteLayout'
import { ReactComponent as BackIcon } from '@icons/ic-arrow-back.svg'
import dayjs from 'dayjs'
import { useVoteQuery } from 'hooks/query/useVoteQuery'
import usePostMessage from 'hooks/usePostMessage'
import { useMutation } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { Option } from 'types'
import { RequestCommand } from 'types/postMessageCommand'

import AddAnswerButton from './components/AddAnswerButton'
import AnswerCountDropdown from './components/AnswerCountDropdown'
import VoteInput from './components/VoteInput'
import VoteLabel from './components/VoteLabel'
import VoteLabelWithSwitch from './components/VoteLabelWithSwitch'

import { VoteAnswer } from '.'

/**
 * @TODO 백 버튼 클릭 시 투표 목록 조회로 가기
 * @TODO 투표자 목록 조회 띄우기
 */

const Edit = () => {
  const { voteId } = useParams()
  const navigate = useNavigate()

  const { data, isLoading, isError } = useVoteQuery(voteId ?? '')
  const vote = data?.data

  useEffect(() => {
    if (vote != null) {
      if (vote?.status === 'ENDED' || !vote?.is_mine) {
        navigate(-1)
      }
    }
  }, [vote])

  if (vote == null) return <VoteLayout />
  if (isLoading) return <VoteLayout />
  if (isError) return <VoteLayout />

  return <EditPresenter vote={vote} />
}

interface EditPresenterProps {
  vote: VoteInterface
}

const EditPresenter: React.FC<EditPresenterProps> = props => {
  const { vote } = props
  const navigate = useNavigate()

  const isAlreadyVoted = vote.voters != null

  const [title, setTitle] = useState(vote.title)

  const initialAnswer: VoteAnswer = useMemo(
    () => ({ content: '', image_url: '' }),
    []
  )

  const initialAnswerList: VoteAnswer[] = useMemo(
    () =>
      vote.options.map(voteOption => ({
        id: voteOption.id,
        content: voteOption.content,
        image_url: voteOption.image_url ?? ''
      })),
    [vote.options]
  )

  const [answerList, setAnswerList] = useState<VoteAnswer[]>([
    ...initialAnswerList,
    { ...initialAnswer }
  ])
  const validAnswerList: VoteAnswer[] = useMemo(
    () =>
      answerList.filter(
        answer => answer.content !== '' || answer.image_url !== ''
      ),
    [answerList]
  )

  const [isMultipleAnswers, setIsMultipleAnswers] = useState(
    vote.max_options > 1
  )

  const multipleAnswerOptionList: Option[] = useMemo(
    () => [
      { value: 2, label: '2' },
      ...Array.from({ length: validAnswerList.length - 2 }, (_, index) => ({
        value: index + 3,
        label: `${index + 3}`
      })),
      { value: validAnswerList.length + 1, label: 'no limit' }
    ],
    [validAnswerList.length]
  )
  const initialMultipleAnswerOption = useMemo(() => {
    const maxOptions = vote.max_options
    if (maxOptions === 1) {
      return multipleAnswerOptionList[0]
    }
    return {
      value: maxOptions,
      label: maxOptions.toString()
    }
  }, [])
  const [multipleAnswerOption, setMultipleAnswerOption] = useState<Option>(
    initialMultipleAnswerOption
  )

  const [isSecret, setIsSecret] = useState(vote.is_secret)

  const viewOptionList: Array<Option<VoteViewResult>> = [
    { value: 'INSTANT', label: 'After voting' },
    { value: 'CLOSED', label: 'After voting ends' },
    { value: 'ALWAYS', label: 'Always view' }
  ]
  const initialViewOption =
    viewOptionList.find(option => option.value === vote.view_result) ??
    viewOptionList[0]
  const [viewOption, setViewOption] = useState<Option>(initialViewOption)

  const [hasPeriod, setHasPeriod] = useState(vote.expiry_date != null)
  const now = useMemo(() => dayjs(), [])
  const initialDate =
    vote.expiry_date != null ? dayjs(vote.expiry_date) : now.add(1, 'day')
  const [date, setDate] = useState(initialDate)
  const [currentTime, setCurrentTime] = useState(initialDate)

  const [errors, setErrors] = useState({
    title: '',
    atLeastAnswer: '',
    date: ''
  })

  const validateTitle = () => {
    if (title.length === 0) {
      setErrors(prevState => ({
        ...prevState,
        title: 'Please type a question'
      }))
      return false
    } else {
      setErrors(prevState => ({ ...prevState, title: '' }))
      return true
    }
  }

  const validateAtLeastAnswer = () => {
    // 2개 이상의 answer가 존재해야 함
    if (validAnswerList.length > 1) return true

    setErrors(prevState => ({
      ...prevState,
      atLeastAnswer: 'At least 2 answers must be registered.'
    }))
    return false
  }

  const validateDate = () => {
    if (!hasPeriod) {
      setErrors(prevState => ({ ...prevState, date: '' }))
      return true
    }
    const regionNow = dayjs().add(60, 'second')
    const standard = date.hour(currentTime.hour()).minute(currentTime.minute())
    if (standard.isBefore(regionNow)) {
      setErrors(prevState => ({
        ...prevState,
        date: 'End date must be at least 60 seconds later.'
      }))
      return false
    }

    setErrors(prevState => ({ ...prevState, date: '' }))
    return true
  }

  const postMessage = usePostMessage()
  const mutation = useMutation(voteApi.patchVote, {
    onSuccess: data => {
      postMessage(RequestCommand.EditSuccess, { data: data.data })
      navigate(-1)
    },
    onError: error => {
      postMessage(RequestCommand.EditFailure, { data: error })
    }
  })

  const onClickSave = () => {
    const voteData = {
      title,
      max_options: isMultipleAnswers ? +multipleAnswerOption.value : 1,
      expiry_date: hasPeriod
        ? date.hour(currentTime.hour()).minute(currentTime.minute())
        : null,
      is_secret: isSecret,
      view_result: viewOption.value,
      options: validAnswerList
    }

    const isTitleValid = validateTitle()
    const isAtLeastAnswerValid = validateAtLeastAnswer()
    const isDateValid = validateDate()

    if (isTitleValid && isAtLeastAnswerValid && isDateValid) {
      mutation.mutate({ id: vote.id, data: voteData })
    }
  }

  const voteFooter = (
    <FixedVoteBottomLayout>
      {/* TODO: Back Button */}
      <JButton
        color="white"
        size="lg"
        label="Back"
        icon={<BackIcon className="mr-[8px] fill-gray-600" />}
        onClick={() => {
          navigate(-1)
        }}
      />

      <JButton
        size="lg"
        label={'Save'}
        disabled={false}
        className="w-[160px]"
        onClick={onClickSave}
      />
    </FixedVoteBottomLayout>
  )

  return (
    <VoteLayout footer={voteFooter} hideHeader>
      {/* TOPIC */}
      <VoteLabel>Topic</VoteLabel>
      <Space height={12} />
      <VoteInput
        value={title}
        onChange={e => {
          setTitle(e.target.value)
          setErrors(prevState => ({ ...prevState, title: '' }))
        }}
        disabled={isAlreadyVoted}
        placeholder="Type your question here"
        maxLength={100}
        errorMessages={errors.title}
      />
      <Space height={24} />
      {/* TYPE */}
      <VoteLabel>Type</VoteLabel>
      {answerList.map((answerDetail, index) => {
        const onChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
          setAnswerList(prevState => {
            const newState = [...prevState]
            newState[index].content = e.target.value
            return newState
          })
          setErrors(prevState => ({ ...prevState, atLeastAnswer: '' }))
        }

        const onChangeImage = (source: string) => {
          setAnswerList(prevState => {
            const newState = [...prevState]
            newState[index].image_url = source
            return newState
          })
        }

        return (
          <Column key={`fixed_${index}`}>
            <Space height={12} />
            <VoteInput
              value={answerDetail.content}
              onChange={onChangeValue}
              placeholder="Type your answers here"
              maxLength={50}
              image={answerDetail.image_url}
              setImage={onChangeImage}
              disabled={answerDetail.id != null && isAlreadyVoted}
              onBlur={() => {
                if (index === answerList.length - 1) validateAtLeastAnswer()
              }}
            />
          </Column>
        )
      })}
      {errors.atLeastAnswer !== '' && (
        <span className="mt-[4px] text-[13px] leading-[1.38] tracking-[-0.26px] text-error-100">
          {errors.atLeastAnswer}
        </span>
      )}
      <Space height={12} />
      <AddAnswerButton
        className="self-end"
        onClick={() => {
          setAnswerList(prevState => [...prevState, { ...initialAnswer }])
        }}
      />
      <Space height={16} />
      <DividedLine />
      {/* MULTIPLE ANSWER */}
      <Space height={16} />
      <VoteLabelWithSwitch
        label="Select multiple answers"
        checked={isMultipleAnswers}
        onChange={setIsMultipleAnswers}
        disabled={isAlreadyVoted}
      />
      {isMultipleAnswers && (
        <Column>
          <Space height={12} />
          <Column className="w-[240px]">
            <AnswerCountDropdown
              optionList={multipleAnswerOptionList}
              selectedOption={multipleAnswerOption}
              onChange={option => {
                setMultipleAnswerOption(option)
              }}
              disabled={isAlreadyVoted}
            />
          </Column>
          <Space height={8} />
        </Column>
      )}
      <Space height={16} />
      <DividedLine />
      {/* SECRET POLL */}
      <Space height={16} />
      <VoteLabelWithSwitch
        label="Secret poll"
        checked={isSecret}
        onChange={setIsSecret}
        disabled={isAlreadyVoted}
      />
      <Space height={16} />
      <DividedLine />
      {/* VIEW RESULT */}
      <Space height={16} />
      <VoteLabel>View results</VoteLabel>
      <Space height={12} />
      <RadioGroup
        groupName="view_result"
        optionList={viewOptionList}
        selectedOption={viewOption}
        onChange={option => {
          setViewOption(option)
        }}
      />
      <Space height={16} />
      <DividedLine />
      {/* END DATE */}
      <Space height={16} />
      <VoteLabelWithSwitch
        label="End date"
        checked={hasPeriod}
        onChange={setHasPeriod}
      />
      <Space height={12} />
      {hasPeriod && (
        <Column>
          <Row className="gap-[4px]">
            <DatePicker date={date} onChange={setDate} />
            <TimePicker
              date={currentTime}
              onChange={setCurrentTime}
              validError={errors.date !== ''}
              isDateBeforeNow={date.isSameOrBefore(now)}
            />
          </Row>
          {errors.date !== '' && (
            <span className="mt-[4px] self-end text-[13px] leading-[1.38] tracking-[-0.26px] text-error-100">
              {errors.date}
            </span>
          )}
        </Column>
      )}
      <Space height={24} />
    </VoteLayout>
  )
}

export default Edit
