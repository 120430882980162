import React, { useEffect, useMemo, useState } from 'react'

import voteApi, { VoteInterface } from '@api/voteApi'
import Button from '@components/button/Button'
import JButton from '@components/button/JButton'
import Column from '@components/common/Column'
import Row from '@components/common/Row'
import Space from '@components/common/Space'
import FixedVoteBottomLayout from '@components/vote/FixedVoteBottomLayout'
import { ReactComponent as BackIcon } from '@icons/ic-arrow-back.svg'
import { ReactComponent as SettingIcon } from '@icons/ic-setting.svg'
import { contextState } from '@store/atoms/contextState'
import dayjs from 'dayjs'
import usePostMessage from 'hooks/usePostMessage'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { RequestCommand } from 'types/postMessageCommand'
import { queryKeys } from 'types/queryKeys'

import VoteDivider from './components/VoteDivider'
import VoteEndIn from './components/VoteEndIn'
import VoteOption from './components/VoteOption/VoteOption'
import Voters from './components/Voters'
import VoteSettingSummary from './components/VoteSettingSummary'
import VoteTitle from './components/VoteTitle'
import VoteLayout from '../../components/vote/VoteLayout'

interface PostedVoteProps {
  vote: VoteInterface
}

// 클럽에서 투표를 게시했거나, 채팅에서 투표를 생성한 경우
const PostedVote: React.FC<PostedVoteProps> = props => {
  const { vote } = props
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const contextData = useRecoilValue(contextState)
  // const route = location.pathname.split('/').pop()

  const [checkedOptionIdList, setCheckedOptionIdList] = useState<number[]>([])
  const [isVoted, setIsVoted] = useState(vote.is_voted)
  const isEnded = vote.status === 'ENDED'
  const isMine = vote.is_mine
  const showProgressing =
    vote.view_result === 'ALWAYS' ||
    (vote.view_result === 'INSTANT' && (isVoted || isEnded)) ||
    (vote.view_result === 'CLOSED' && isEnded)

  useEffect(() => {
    if (vote == null) return
    setCheckedOptionIdList(
      vote.options
        .filter(option => option?.is_selected ?? false)
        .map(option => option.id)
    )
  }, [vote])

  const postMessage = usePostMessage()

  const onClickOptionImage = (source: string) => {
    const imageSourceList = vote.options
      .map(option => option.image_url ?? '')
      .filter(src => src !== '')

    postMessage(RequestCommand.ClickVoteOptionImage, {
      data: {
        index: imageSourceList.findIndex(src => src === source) ?? 0,
        sourceList: imageSourceList
      }
    })
  }

  const voteButtonDisabled = useMemo(() => {
    if (vote == null) return true
    if (isEnded) return true
    if (vote.max_options === 1) return checkedOptionIdList.length !== 1
    return (
      checkedOptionIdList.length === 0 ||
      checkedOptionIdList.length > vote.max_options
    )
  }, [checkedOptionIdList, vote])

  const createVoteMutation = useMutation(voteApi.postVoteCast, {
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: queryKeys.vote })
      setIsVoted(true)
      postMessage(RequestCommand.VoteSuccess)
    },
    onError: error => {
      console.error(error)
    }
  })

  const onClickVoteButton = () => {
    if (isVoted) {
      setIsVoted(false)
      return
    }

    createVoteMutation.mutate({
      id: vote.id,
      data: { ids: checkedOptionIdList }
    })
  }

  // const voteStopApiFunction =
  //   route === 'club'
  //     ? clubApi.postVoteStop
  //     : route === 'forum'
  //     ? forumApi.postVoteStop
  //     : voteApi.postVoteStop

  const stopVoteMutation = useMutation(voteApi.postVoteStop, {
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: queryKeys.vote })
      postMessage(RequestCommand.StopVote, { data: { voteId: vote.id } })
    },
    onSettled: () => {
      navigate(0)
    }
  })

  const onClickStop = () => {
    stopVoteMutation.mutate({ id: vote.id })
  }

  const settingButton = (
    <Button
      className="!inline align-middle"
      onClick={() => {
        navigate(`/edit/${vote.id}`)
      }}
    >
      <SettingIcon className=" size-[20px] fill-gray-300 " />
    </Button>
  )

  const onClickBackButton = () => {
    if (vote.club_id != null) {
      navigate(`/votes/club/${vote.club_id}`)
    } else if (vote.channel_url != null) {
      navigate(`/votes/chat/${vote.channel_url}`)
    } else if (vote.forum_id != null) {
      navigate(`/votes/forum/${vote.forum_id}`)
    }
    postMessage(RequestCommand.NavigateVoteList)
  }

  const voteFooter = (
    <FixedVoteBottomLayout>
      <JButton
        size="lg"
        label="Back"
        color="white"
        icon={<BackIcon className="mr-[8px] fill-gray-600" />}
        onClick={onClickBackButton}
      />

      <JButton
        size="lg"
        label={isEnded ? 'Confirm' : isVoted ? 'Vote again' : 'Vote'}
        disabled={voteButtonDisabled}
        className="w-[160px]"
        onClick={onClickVoteButton}
      />
    </FixedVoteBottomLayout>
  )

  return (
    <VoteLayout footer={voteFooter} hideHeader>
      <VoteTitle voteTitle={vote?.title} />
      <Space height={8} />

      <VoteSettingSummary
        vote={vote}
        settingButton={isMine && !isEnded && settingButton}
      />
      <Space height={20} />

      <Column className="gap-[24px]">
        {vote.options.map(option => {
          const onClickCheckbox = () => {
            if (vote.max_options === 1) {
              setCheckedOptionIdList([option.id])
            } else {
              if (checkedOptionIdList.includes(option.id)) {
                setCheckedOptionIdList(prev =>
                  prev.filter(id => id !== option.id)
                )
              } else {
                if (checkedOptionIdList.length >= vote.max_options) {
                  return
                }
                setCheckedOptionIdList(prev => [...prev, option.id])
              }
            }
          }

          return (
            <VoteOption
              key={option.id}
              option={option}
              checked={checkedOptionIdList.includes(option.id)}
              onClick={onClickCheckbox}
              isVoted={isVoted}
              isEnded={isEnded}
              showProgressing={showProgressing}
              onClickImage={() => {
                if (option.image_url != null) {
                  onClickOptionImage(option.image_url)
                }
              }}
              isMostVoted={vote.most_voted_options?.includes?.(option.id)}
            />
          )
        })}
      </Column>
      <Space height={24} />

      {vote.expiry_date != null && !isEnded && (
        <>
          <VoteDivider />
          <Space height={24} />
          <VoteEndIn isEnded={isEnded} expiryDate={dayjs(vote.expiry_date)} />
          <Space height={24} />
        </>
      )}

      {!vote.is_secret && vote.voters != null && vote.voters.length > 0 && (
        <>
          <VoteDivider />
          <Space height={24} />
          <Voters
            voteCount={vote.vote_count}
            voters={vote.voters}
            onClickMore={() => {
              postMessage(RequestCommand.ClickVoters, {
                data: { voters: vote.voters }
              })
            }}
          />
          <Space height={24} />
        </>
      )}

      {(isMine ||
        (contextData?.canStopVote != null && contextData.canStopVote)) &&
        !isEnded && (
          <>
            <Row className="justify-end">
              <JButton
                label="Stop Vote"
                color="default"
                size="sm"
                onClick={onClickStop}
              />
            </Row>
            <Space height={24} />
          </>
        )}
    </VoteLayout>
  )
}

export default PostedVote
