import dayjs, { Dayjs } from 'dayjs'

export enum DateFormatterType {
  Short,
  Normal,
  Long
}

export const dateFormatter: (date: Dayjs, type?: DateFormatterType) => string =
  (date, type = DateFormatterType.Long) => {
    const now = dayjs()

    let prevDate = date
    let nextDate = now

    if (nextDate.diff(prevDate) < 0) {
      prevDate = now
      nextDate = date
    }

    const yearDiff = nextDate.diff(prevDate, 'year')
    const weekDiff = nextDate.diff(prevDate, 'week')
    const monthDiff = nextDate.diff(prevDate, 'month')
    const dayDiff = nextDate.diff(prevDate, 'day')
    const hourDiff = nextDate.diff(prevDate, 'hour')
    const minuteDiff = nextDate.diff(prevDate, 'minute')

    if (type === DateFormatterType.Short) {
      switch (true) {
        case yearDiff > 0:
          return `${weekDiff}w`
        case monthDiff > 0 || dayDiff > 6:
          return `${weekDiff}w`
        case dayDiff > 0:
          return `${dayDiff}d`
        case hourDiff > 0:
          return `${hourDiff}h`
        case minuteDiff > 0:
          return `${minuteDiff}m`
        default:
          return '0m'
      }
    } else if (type === DateFormatterType.Normal) {
      switch (true) {
        case yearDiff > 0:
          return date.format('MMM. D, YYYY')
        case monthDiff > 0 || dayDiff > 6:
          return date.format('MMM. D')
        case dayDiff > 0:
          return `${dayDiff}d ago`
        case hourDiff > 0:
          return `${hourDiff}h ago`
        case minuteDiff > 0:
          return `${minuteDiff}m ago`
        default:
          return '0m ago'
      }
    } else if (type === DateFormatterType.Long) {
      switch (true) {
        case yearDiff > 0:
          return date.format('MMM. D, YYYY')
        case monthDiff > 0 || dayDiff > 6:
          return date.format('MMM. D')
        case dayDiff > 0:
          return dayDiff === 1 ? `1day ago` : `${dayDiff}days ago`
        case hourDiff > 0:
          return hourDiff === 1 ? `1hour ago` : `${hourDiff}hours ago`
        case minuteDiff > 0:
          return minuteDiff === 1 ? `1min ago` : `${minuteDiff}mins ago`
        default:
          return '0min ago'
      }
    }

    return ''
  }
