// import axiosDefault from 'axios'
// import _ from 'lodash'

import axios from './axios.config'

const voteBaseUrl: string = process.env.REACT_APP_VOTE_API_URL ?? ''

export interface ApiProps {
  id?: number
  data?: any
  params?: any
}

export interface DjangoInterface<T> {
  data: T
  code: number
  message: string
}

export interface VoteUserInterface {
  id: number
  profile_image_url: string
  username: string
}

export interface VoteInterface {
  id: number
  title: string
  is_secret: boolean
  is_mine: true
  is_voted: boolean
  created: string
  expiry_date?: string
  ended_at?: string
  forum_id?: number
  post_id?: number
  chat_id?: number
  club_id?: number
  profile_id?: number
  max_options: number
  options: VoteOptionInterface[]
  status: VoteStatus
  view_result: VoteViewResult
  vote_count: number
  voters?: VoterInterface[] | null
  user?: VoteUserInterface
  most_voted_options?: number[]
  channel_url?: string
}

export interface VoteOptionInterface {
  id: number
  content: string
  created: string
  image_url?: string | null
  is_selected?: boolean
  vote_ratio?: number
  option_count?: number
}

export interface VoterInterface {
  user: VoteUserInterface
  created: string
}

export type VoteStatus = 'VOTING' | 'ENDED'
export type VoteViewResult = 'INSTANT' | 'CLOSED' | 'ALWAYS'

const postAdminUserSignup = async (props: ApiProps) =>
  await axios.post(`${voteBaseUrl}/v1/admin/user/signup`, props?.data)
const patchAdminUserPassword = async (props: ApiProps) =>
  await axios.patch(
    `${voteBaseUrl}/v1/admin/user/${props.id as number}/password`,
    props?.data
  )
const deleteOption = async (props: ApiProps) =>
  await axios.delete(`${voteBaseUrl}/v1/option/${props.id as number}`, {
    params: props?.params
  })
const getOptions = async (props: ApiProps) =>
  await axios.get(`${voteBaseUrl}/v1/options`, { params: props?.params })
const postToken = async (props: ApiProps) =>
  await axios.post(`${voteBaseUrl}/v1/token/`, props?.data)
const postTokenRefresh = async (props: ApiProps) =>
  await axios.post(`${voteBaseUrl}/v1/token/refresh/`, props?.data)
const postTokenVerify = async (props: ApiProps) =>
  await axios.post(`${voteBaseUrl}/v1/token/verify/`, props?.data)
const postUser = async (props: ApiProps) =>
  await axios.post(`${voteBaseUrl}/v1/user`, props?.data)
const getUserMe = async (props: ApiProps) =>
  await axios.get(`${voteBaseUrl}/v1/user/me`, { params: props?.params })
const postUserSync = async (props: ApiProps) =>
  await axios.post(`${voteBaseUrl}/v1/user/sync`, props?.data)
const getUsers = async (props: ApiProps) =>
  await axios.get(`${voteBaseUrl}/v1/users`, { params: props?.params })
const postVote = async (props: ApiProps) =>
  await axios.post(`${voteBaseUrl}/v1/vote`, props?.data)
const getVote = async (props: ApiProps) =>
  await axios.get(`${voteBaseUrl}/v1/vote/${props.id as number}`, {
    params: props?.params
  })
const patchVote = async (props: ApiProps) =>
  await axios.patch(`${voteBaseUrl}/v1/vote/${props.id as number}`, props?.data)
const postVoteCast = async (props: ApiProps) =>
  await axios.post(
    `${voteBaseUrl}/v1/vote/${props.id as number}/cast`,
    props?.data
  )
const postVoteStop = async (props: ApiProps) =>
  await axios.post(
    `${voteBaseUrl}/v1/vote/${props.id as number}/stop`,
    props?.data
  )
const getVotes = async (props: ApiProps) =>
  await axios.get(`${voteBaseUrl}/v1/votes`, { params: props?.params })

export default {
  postAdminUserSignup,
  patchAdminUserPassword,
  deleteOption,
  getOptions,
  postToken,
  postTokenRefresh,
  postTokenVerify,
  postUser,
  getUserMe,
  postUserSync,
  getUsers,
  postVote,
  getVote,
  patchVote,
  postVoteCast,
  postVoteStop,
  getVotes
}
