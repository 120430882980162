import React, { useCallback, useEffect, useMemo, useState } from 'react'

import Column from '@components/common/Column'
import Row from '@components/common/Row'
import { ReactComponent as ColonIcon } from '@icons/ic-colon.svg'
import dayjs, { Dayjs } from 'dayjs'

interface TimeDisplayProps {
  value: number
  label: string
}

const MILLISECONDS_IN_SECOND = 1000
const SECONDS_IN_MINUTE = 60
const MINUTES_IN_HOUR = 60
const HOURS_IN_DAY = 24

const TimeDisplay: React.FC<TimeDisplayProps> = props => {
  const { value, label } = props
  return (
    <Column className="h-[68px] w-[68px] items-center pt-[8px]">
      <span className="text-[24px] font-bold leading-[33px] tracking-[-0.48px] text-black-200">
        {value.toString().padStart(2, '0')}
      </span>
      <span className="text-[13px] leading-[18px] tracking-[-0.26px] text-gray-600">
        {label}
      </span>
    </Column>
  )
}

interface TimerProps {
  expiryDate: Dayjs
  timedOut?: boolean
}

const Timer: React.FC<TimerProps> = props => {
  const { expiryDate, timedOut = false } = props

  const getTimeLeft = useCallback((date: Dayjs) => {
    const now = dayjs()
    const difference = date.diff(now)

    if (difference <= 0) {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
      }
    }

    const totalSeconds = Math.floor(difference / MILLISECONDS_IN_SECOND)
    const totalMinutes = Math.floor(totalSeconds / SECONDS_IN_MINUTE)
    const totalHours = Math.floor(totalMinutes / MINUTES_IN_HOUR)
    const totalDays = Math.floor(totalHours / HOURS_IN_DAY)

    return {
      days: totalDays,
      hours: totalHours % HOURS_IN_DAY,
      minutes: totalMinutes % MINUTES_IN_HOUR,
      seconds: totalSeconds % SECONDS_IN_MINUTE
    }
  }, [])

  const zeroDiff = useMemo(
    () => ({
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0
    }),
    []
  )
  const [timeDiff, setTimeDiff] = useState(
    timedOut ? zeroDiff : getTimeLeft(expiryDate)
  )

  // 1초마다 시간 갱신
  useEffect(() => {
    const timer = setTimeout(() => {
      if (timedOut) {
        setTimeDiff(zeroDiff)
      } else {
        setTimeDiff(getTimeLeft(expiryDate))
      }
    }, 1000)
    return () => {
      clearTimeout(timer)
    }
  })

  return (
    <Row className="h-[84px] items-center justify-center rounded-[8px] bg-gray-100">
      <TimeDisplay value={timeDiff.days} label="Days" />
      <ColonIcon className="mx-[16px] h-[20px] w-[6px]" />
      <TimeDisplay value={timeDiff.hours} label="Hours" />
      <ColonIcon className="mx-[16px] h-[20px] w-[6px]" />
      <TimeDisplay value={timeDiff.minutes} label="Minutes" />
      <ColonIcon className="mx-[16px] h-[20px] w-[6px]" />
      <TimeDisplay value={timeDiff.seconds} label="Seconds" />
    </Row>
  )
}

export default Timer
