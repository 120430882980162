import React from 'react'

import Row from '@components/common/Row'
import Input, { InputProps } from '@components/input/Input'
import styled from '@emotion/styled'
import { ReactComponent as SearchIcon } from '@icons/ic-search.svg'

interface SearchInputProps extends InputProps {}

const SearchInput: React.FC<SearchInputProps> = props => {
  const { value, onChange, placeholder, className = '', ...restProps } = props
  return (
    <Row className="relative items-center">
      <StyledInput
        className={className}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        {...restProps}
      />
      <SearchIcon fill="#bdbdbd" className="absolute right-[10px]" />
    </Row>
  )
}

export default SearchInput

const StyledInput = styled(Input)`
  height: 44px;
  width: 280px;
  border-radius: 8px;

  padding-left: 16px;
  padding-right: 40px;

  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.28px;
  background-color: rgb(248 248 248);
  color: rgb(36 42 60);

  ::placeholder {
    color: rgb(189 189 189);
  }

  :focus {
    border: 1px solid rgb(144 19 254);
  }
`
