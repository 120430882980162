import React from 'react'

import styled from '@emotion/styled'

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  type?: string
  placeholder?: string
  value?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  className?: string
}

const Input: React.FC<InputProps> = props => {
  const { type, placeholder, value, onChange, className, ...restProps } = props
  return (
    <StyledInput
      className={className}
      value={value}
      onChange={onChange}
      type={type}
      placeholder={placeholder}
      {...restProps}
    />
  )
}

export default Input

const StyledInput = styled.input`
  border-style: none;
  outline: 2px solid transparent;
  outline-offset: 2px;
`
