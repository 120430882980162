import React from 'react'

import { ButtonProps } from './Button'
import SizedButton from './SizedButton'
import SizedButtonLabel from './SizedButtonLabel'

export type ButtonSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl'
export type ButtonColor = 'primary' | 'default' | 'gray' | 'white'

interface JButtonProps extends ButtonProps {
  label: string
  color?: ButtonColor
  size?: ButtonSize
  icon?: React.ReactNode
}

const JButton: React.FC<JButtonProps> = props => {
  const {
    label,
    color = 'primary',
    className = '',
    size = 'md',
    icon = null,
    disabled = false,
    ..._props
  } = props

  return (
    <SizedButton
      {..._props}
      size={size}
      disabled={disabled}
      className={`items-center justify-center ${
        color === 'primary'
          ? 'border border-primary-200 bg-primary-100 hover:bg-primary-200 active:bg-primary-300 disabled:border-gray-400 disabled:bg-gray-400'
          : color === 'default'
          ? 'border border-gray-500 bg-gray-100 hover:bg-gray-200 active:bg-gray-300 disabled:border-gray-300 disabled:bg-gray-100'
          : color === 'gray'
          ? 'border border-gray-500 bg-gray-100 hover:bg-gray-200 active:bg-gray-300 disabled:border-gray-400 disabled:bg-gray-400'
          : color === 'white'
          ? 'bg-white hover:bg-gray-100 active:bg-gray-200'
          : ''
      } ${className}`}
    >
      {icon}
      <SizedButtonLabel
        size={size}
        className={`${
          color === 'primary'
            ? `${disabled ? 'text-white' : 'text-white'} `
            : color === 'default'
            ? `${disabled ? 'text-gray-300' : 'text-black-200'} `
            : color === 'gray'
            ? `${disabled ? 'text-black-200' : 'text-black-200'} `
            : color === 'white'
            ? `${disabled ? 'text-gray-700' : 'text-gray-700'} `
            : ''
        }`}
      >
        {label}
      </SizedButtonLabel>
    </SizedButton>
  )
}

export default JButton
