import React, { useState } from 'react'

import voteApi, { DjangoInterface, VoteInterface } from '@api/voteApi'
import JButton from '@components/button/JButton'
import DividedLine from '@components/common/DividedLine'
import Row from '@components/common/Row'
import Space from '@components/common/Space'
import CategoryTabs from '@components/tabs/CategoryTabs'
import FixedVoteBottomLayout from '@components/vote/FixedVoteBottomLayout'
import VoteLayout from '@components/vote/VoteLayout'
import styled from '@emotion/styled'
import { ReactComponent as RefreshIcon } from '@icons/ic-refresh.svg'
import { useQuery } from 'react-query'
import { useLocation, useParams } from 'react-router-dom'
import { Option } from 'types'
import { queryKeys } from 'types/queryKeys'

import OrderingTabs from './OrderingTabs'
import SearchInput from './SearchInput'
import VoteItem from './VoteItem'
import VoteList from './VoteList'

const Votes: React.FC = () => {
  const { clubId, channelUrl, forumId } = useParams()
  const location = useLocation()
  const isClub = location.pathname.includes('club') && Boolean(clubId)
  const isChat = location.pathname.includes('chat') && Boolean(channelUrl)
  const isForum = location.pathname.includes('forum') && Boolean(forumId)
  // const navigate = useNavigate()

  const statusFilterOptionList: Option[] = [
    { value: 'VOTING', label: 'Voting' },
    { value: 'ENDED', label: 'Ended' }
  ]
  const [statusFilterOption, setStatusFilterOption] = useState<Option>(
    statusFilterOptionList[0]
  )

  const orderingOptionList: Option[] = [
    { value: '-vote_count', label: 'Best' },
    { value: '-new', label: 'New' }
  ]
  const [orderingOption, setOrderingOption] = useState<Option>(
    orderingOptionList[0]
  )

  const [searchWord, setSearchWord] = useState('')

  const { data, isLoading, refetch } = useQuery<
    DjangoInterface<VoteInterface[]>
  >(
    [
      queryKeys.voteList,
      clubId,
      statusFilterOption.value,
      orderingOption.value,
      searchWord
    ],
    async () => {
      const response = await voteApi.getVotes({
        params: {
          club: clubId,
          forum_id: forumId,
          channel_url: channelUrl,
          status: statusFilterOption.value,
          ordering: orderingOption.value,
          search: searchWord
        }
      })
      return response.data
    },
    { enabled: isClub || isChat || isForum }
  )
  const voteList: VoteInterface[] = data?.data != null ? data.data : []

  const voteFooter = (
    <FixedVoteBottomLayout>
      <JButton
        size="lg"
        color="white"
        label="Refresh"
        icon={<StyledRefreshIcon />}
        onClick={() => {
          void refetch()
        }}
      />
    </FixedVoteBottomLayout>
  )

  return (
    <VoteLayout footer={voteFooter} hideHeader>
      <Row className="items-end justify-between">
        <CategoryTabs
          optionList={statusFilterOptionList}
          onChange={setStatusFilterOption}
          selectedOption={statusFilterOption}
        />

        <SearchInput
          value={searchWord}
          onChange={e => {
            setSearchWord(e.target.value)
          }}
          placeholder="Search.."
        />
      </Row>
      <Space height={12} />
      <DividedLine />
      <Space height={12} />
      <Row className="justify-end">
        <OrderingTabs
          optionList={orderingOptionList}
          onChange={setOrderingOption}
          selectedOption={orderingOption}
        />
      </Row>
      <Space height={8} />
      {isLoading && null}
      {!isLoading && (
        <VoteList>
          {voteList.map(vote => (
            <VoteItem key={vote.id} vote={vote} />
          ))}
        </VoteList>
      )}
    </VoteLayout>
  )
}

export default Votes

const StyledRefreshIcon = styled(RefreshIcon)`
  margin-right: 4px;
  fill: #bdbdbd;
  stroke: none;
`
