import React, { Dispatch, SetStateAction } from 'react'

import Button from '@components/button/Button'
import Row from '@components/common/Row'
import { Dayjs } from 'dayjs'

import SelectorLayout from './SelectorLayout'

interface MonthSelectorProps {
  now: Dayjs
  uiDate: Dayjs
  setUiDate: Dispatch<SetStateAction<Dayjs>>
  disabledBeforeDate: boolean
  close: () => void
}

const MonthSelector: React.FC<MonthSelectorProps> = props => {
  const {
    //
    now,
    uiDate,
    setUiDate,
    disabledBeforeDate,
    close
  } = props

  return (
    <SelectorLayout close={close}>
      <Row className="flex-wrap justify-between gap-y-[6px]">
        {Array.from({ length: 12 }).map((_, index) => {
          const month = index + 1
          const disabled =
            disabledBeforeDate &&
            uiDate.isSame(now, 'year') &&
            month < now.month() + 1

          // uiDate의 month와 month가 같고, uiDate의 year와 now의 year가 같으면 true
          const isSameMonth = uiDate.month() === month - 1

          return (
            <Button
              key={index}
              className={`group/dp_month_btn flex h-[30px] w-[96px] items-center justify-center rounded-full
                        ${
                          !disabled
                            ? `${
                                isSameMonth
                                  ? 'bg-primary-100 hover:bg-primary-200'
                                  : 'hover:bg-gray-200'
                              }`
                            : ''
                        }`}
              disabled={disabled}
              onClick={() => {
                setUiDate(prev => prev.month(month - 1))
                close()
              }}
            >
              <span
                className={`text-[13px] leading-[1.54] tracking-[-0.26px] text-black-200 group-disabled/dp_month_btn:text-gray-500 ${
                  isSameMonth ? 'text-white' : ''
                }`}
              >
                {uiDate.month(month - 1).format('MMMM')}
              </span>
            </Button>
          )
        })}
      </Row>
    </SelectorLayout>
  )
}

export default MonthSelector
