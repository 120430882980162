import React from 'react'

import Button from '@components/button/Button'
import Row from '@components/common/Row'
import { Option } from 'types'

interface CategoryTabsProps {
  optionList: Option[]
  selectedOption: Option
  onChange: (option: Option) => void
}

const CategoryTabs: React.FC<CategoryTabsProps> = props => {
  const { optionList, selectedOption, onChange } = props
  return (
    <Row className="gap-[6px] pt-[8px]">
      {optionList.map((option, index) => {
        const isActive = option.value === selectedOption.value
        const onClick = () => {
          onChange(option)
        }

        return (
          <Button
            key={index}
            onClick={onClick}
            className={`rounded-[8px] border px-[16px] py-[8px] hover:brightness-95 active:brightness-90 ${
              isActive
                ? `border-transparent bg-gradient-to-r from-point-0 to-point-100 shadow-[0_0_8px_0_rgba(0,0,0,0.15)] `
                : `border-gray-300 bg-white`
            }`}
          >
            <span
              className={`text-[14px] font-medium leading-[1.43] tracking-[-0.28px] ${
                isActive ? 'text-white' : 'text-black-100'
              }`}
            >
              {option.label}
            </span>
          </Button>
        )
      })}
    </Row>
  )
}

export default CategoryTabs
