import React from 'react'

import styled from '@emotion/styled'

interface RowProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode
  className?: string
}

const Row: React.FC<RowProps> = (props: RowProps) => {
  const { children, className = '', ...restProps } = props
  return (
    <StyledRow className={className} {...restProps}>
      {children}
    </StyledRow>
  )
}

export default Row

const StyledRow = styled.div`
  display: flex;
`
