import React, { useMemo } from 'react'

import { ButtonSize } from './SizedButton'

type ButtonLabelSize = ButtonSize

interface SizedButtonLabelProps extends React.HTMLAttributes<HTMLSpanElement> {
  size?: ButtonLabelSize
}

const SizedButtonLabel: React.FC<SizedButtonLabelProps> = props => {
  const { children, size = 'md', className = '', ..._props } = props

  const labelSizeClassName = useMemo(() => {
    switch (size) {
      case 'xs':
        return 'text-[13px] font-medium leading-[1.38] tracking-[-0.26px]'
      case 'sm':
        return 'text-[14px] font-medium leading-[1.43] tracking-[-0.28px]'
      case 'md':
        return 'text-[15px] font-medium leading-[1.47] tracking-[-0.3px]'
      case 'lg':
        return 'text-[17px] font-medium leading-[1.53] tracking-[-0.34px]'
      case 'xl':
        return 'text-[20px] font-medium leading-[1.5] tracking-[-0.4px]'
      default:
        return ''
    }
  }, [size])

  return (
    <span {..._props} className={`${labelSizeClassName} ${className}`}>
      {children}
    </span>
  )
}

export default SizedButtonLabel
