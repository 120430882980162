import React from 'react'

import Column from '@components/common/Column'

interface VoteListProps {
  children?: React.ReactNode
}

const VoteList: React.FC<VoteListProps> = props => {
  const { children } = props

  return <Column className="pb-[60px]">{children}</Column>
}

export default VoteList
