import React from 'react'

import Column from '@components/common/Column'

interface SelectorLayoutProps {
  children: React.ReactNode
  close: () => void
}

const SelectorLayout: React.FC<SelectorLayoutProps> = props => {
  const { children, close } = props
  return (
    <Column
      className="absolute inset-x-0 bottom-0 top-[64px] bg-black-100/[0.7]"
      onClick={close}
    >
      <Column
        className="relative bg-white px-[20px] pb-[20px]"
        onClick={e => {
          e.stopPropagation()
        }}
      >
        {children}
      </Column>
    </Column>
  )
}

export default SelectorLayout
