import React from 'react'

import styled from '@emotion/styled'

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

const Button: React.FC<ButtonProps> = props => {
  const { children, className = '', ...restProps } = props
  return (
    <StyledButton className={className} {...restProps}>
      {children}
    </StyledButton>
  )
}

export default Button

const StyledButton = styled.button`
  display: flex;
`
