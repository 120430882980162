import React, { useMemo, useState } from 'react'

import PrimaryButton from '@components/button/PrimaryButton'
import Column from '@components/common/Column'
import Row from '@components/common/Row'
import dayjs, { Dayjs } from 'dayjs'
import { useOnClickOutside } from 'hooks/useOnClickOutside'

import AmPmSelector from './AmPmSelector'
import HourSelector from './HourSelector'
import MinuteSelector from './MinuteSelector'
import TimePickerInput from './TimePickerInput'

interface TimePickerProps {
  date: Dayjs
  onChange: (date: Dayjs) => void
  validError: boolean
  isDateBeforeNow?: boolean
}

const itemHeight = 36
const itemSpacing = 5

const TimePicker: React.FC<TimePickerProps> = props => {
  const { date, isDateBeforeNow = true, onChange: onSubmit, validError } = props
  const now: Dayjs = useMemo(() => dayjs(), [])

  const { isOpen, setIsOpen, ref } = useOnClickOutside()

  const [uiDate, setUiDate] = useState<Dayjs>(date)

  const confirmDisabled = useMemo(
    () => isDateBeforeNow && uiDate.isSameOrBefore(now),
    [uiDate, now]
  )

  return (
    <div ref={ref} className="relative flex w-full flex-col">
      <TimePickerInput
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        selectedDate={date}
        validError={validError}
      />

      {isOpen && (
        <Column className="absolute left-0 top-[58px] z-10 w-[270px]">
          <Column className="relative w-full overflow-hidden rounded-[8px] border border-gray-600 bg-white shadow-[0_2px_6px_0_rgba(0,0,0,0.2)]">
            <NowDisplay now={now} />
            <div className="absolute left-0 top-[160px] flex h-[36px] w-full bg-gray-100 pl-[80px] pt-[4px]">
              :
            </div>

            <Row className="relative justify-around">
              <Column>
                <SelectorLabel label="Hour" />
                <HourSelector
                  initDate={date}
                  uiDate={uiDate}
                  setUiDate={setUiDate}
                  itemHeight={itemHeight}
                  itemSpacing={itemSpacing}
                />
              </Column>
              <Column className="">
                <SelectorLabel label="Minute" />
                <MinuteSelector
                  initDate={date}
                  uiDate={uiDate}
                  setUiDate={setUiDate}
                  itemHeight={itemHeight}
                  itemSpacing={itemSpacing}
                />
              </Column>
              <Column className="">
                <SelectorLabel label="AM/PM" />
                <AmPmSelector
                  initDate={date}
                  uiDate={uiDate}
                  setUiDate={setUiDate}
                  itemHeight={itemHeight}
                  itemSpacing={itemSpacing}
                />
              </Column>
            </Row>

            <Column className="px-[20px] pb-[16px] pt-[12px]">
              <PrimaryButton
                label="Confirm"
                disabled={confirmDisabled}
                onClick={() => {
                  onSubmit(uiDate)
                  setIsOpen(false)
                }}
              />
            </Column>
          </Column>
        </Column>
      )}
    </div>
  )
}

const SelectorLabel = ({ label }: { label: string }) => (
  <span className="px-[4px] py-[6px] text-[14px] font-medium leading-[1.43] tracking-[-0.28px] text-gray-600">
    {label}
  </span>
)

const NowDisplay: React.FC<{ now: Dayjs }> = ({ now }) => (
  <Row className="items-center justify-between px-[20px] py-[16px]">
    <span className="text-[16px] leading-[1.5] tracking-[-0.32px] text-black-200">
      NOW
    </span>
    <span className="text-[16px] font-medium leading-[1.5] tracking-[-0.32px] text-black-100">
      {now.format('hh : mm A')}
    </span>
  </Row>
)

export default TimePicker
