import { VoteStatus } from '@api/voteApi'
import { atom } from 'recoil'

export interface ContextStateInterface {
  canStopVote?: boolean
  isEditMode?: boolean
  voteMessageStatus?: VoteStatus
}

export const contextState = atom<ContextStateInterface | null>({
  key: 'contextState',
  default: null
})
