import React from 'react'

import styled from '@emotion/styled'

interface CenterProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode
  className?: string
}

const Center: React.FC<CenterProps> = (props: CenterProps) => {
  const { children, className = '', ...restProps } = props
  return (
    <StyledDiv className={className} {...restProps}>
      {children}
    </StyledDiv>
  )
}

export default Center

const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`
