import ChatMessage from '@pages/ChatMessage'
import Create from '@pages/Create'
import Edit from '@pages/Create/Edit'
import Root from '@pages/Root'
import Vote from '@pages/Vote'
import Voters from '@pages/Voters'
import Votes from '@pages/Votes'
import { Route } from 'react-router-dom'

const RootRouter = (
  <Route path="/" element={<Root />}>
    <Route path="votes" element={<Votes />} />
    <Route path="votes/club/:clubId" element={<Votes />} />
    <Route path="votes/chat/:channelUrl" element={<Votes />} />
    <Route path="votes/forum/:forumId" element={<Votes />} />
    <Route path="voters/:voteId" element={<Voters />} />
    <Route path="create" element={<Create />} />
    <Route path="edit/:voteId" element={<Edit />} />
    <Route path="vote/:voteId/*" element={<Vote />} />
    <Route path="chat-message/:voteId" element={<ChatMessage />} />
  </Route>
)

export default RootRouter
