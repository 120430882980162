import React from 'react'

import Button from '@components/button/Button'
import DropdownContainer from '@components/dropdown/DropdownContainer'
import { ReactComponent as ArrowUpIcon } from '@icons/ic-arrow-up.svg'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import { Option } from 'types'

interface AnswerCountDropdownProps {
  optionList: Option[]
  selectedOption: Option
  onChange: (option: Option) => void
  disabled?: boolean
}

const AnswerCountDropdown: React.FC<AnswerCountDropdownProps> = props => {
  const { optionList, selectedOption, onChange, disabled = false } = props
  const { isOpen, setIsOpen, ref } = useOnClickOutside()

  return (
    <div ref={ref} className="relative z-10 flex">
      <Button
        onClick={() => {
          setIsOpen(!isOpen)
        }}
        className={`flex h-[52px] w-full items-center justify-between rounded-[8px] border border-gray-300 pl-[16px] pr-[12px] ${
          disabled
            ? 'cursor-not-allowed bg-gray-100'
            : `bg-white ${
                isOpen ? 'border-primary-100' : 'hover:border-black-200 '
              }`
        }`}
        disabled={disabled}
      >
        <span
          className={`text-[16px] leading-[1.5] tracking-[-0.32px] ${
            disabled ? 'text-gray-500' : 'text-black-100'
          }`}
        >
          {selectedOption.label}
        </span>
        <ArrowUpIcon
          className={`transition ${
            isOpen ? 'stroke-black-200' : 'rotate-180 stroke-gray-500'
          }`}
        />
      </Button>

      {isOpen && (
        <DropdownContainer
          optionList={optionList}
          onChange={option => {
            onChange(option)
            setIsOpen(false)
          }}
          selectedOption={selectedOption}
        />
      )}
    </div>
  )
}

export default AnswerCountDropdown
