import React from 'react'

import { ButtonProps } from './Button'
import SizedButton from './SizedButton'
import SizedButtonLabel from './SizedButtonLabel'

export type ButtonSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl'

interface PrimaryButtonProps extends ButtonProps {
  label: string
  size?: ButtonSize
}

const PrimaryButton: React.FC<PrimaryButtonProps> = props => {
  const { label, className = '', size = 'md', ..._props } = props

  return (
    <SizedButton
      {..._props}
      size={size}
      className={`group/base_primary_btn items-center justify-center border border-primary-200 bg-primary-100 hover:bg-primary-200 disabled:border-gray-400 disabled:bg-gray-400 ${className}`}
    >
      <SizedButtonLabel className="text-white" size={size}>
        {label}
      </SizedButtonLabel>
    </SizedButton>
  )
}

export default PrimaryButton
