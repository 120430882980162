import React from 'react'

import { VoteOptionInterface } from '@api/voteApi'
import { ReactComponent as CheckIcon } from '@assets/icons/ic-check.svg'
import Checkbox from '@components/checkbox/Checkbox'
import Column from '@components/common/Column'
import Row from '@components/common/Row'
import classNames from 'classnames'

import VoteOptionImage from './VoteOptionImage'
import VoteRate from './VoteRate'

interface VoteOptionProps {
  option: VoteOptionInterface
  isVoted: boolean
  checked?: boolean
  onClick?: () => void
  isEnded: boolean
  showProgressing: boolean
  onClickImage?: () => void
  isMostVoted?: boolean
  disabledImage?: boolean
  isSmall?: boolean
}

const VoteOption: React.FC<VoteOptionProps> = props => {
  const {
    option,
    isVoted,
    checked = null,
    onClick,
    isEnded,
    showProgressing,
    onClickImage,
    isMostVoted = false,
    disabledImage = false,
    isSmall = false
  } = props

  const isSelected = Boolean(option.is_selected)
  const voteRate = option.vote_ratio != null ? Math.round(option.vote_ratio) : 0
  const voteCount = option.option_count != null ? option.option_count : 0
  const isEndedAndMostVoted = isEnded && isMostVoted

  return (
    <Column
      key={option.id}
      className={classNames('shrink-0', isSmall ? 'gap-[8px]' : 'gap-[20px]', {
        'my-[-12px] ml-[-24px] w-[calc(100%+48px)] bg-gray-100 px-[24px] py-[12px]':
          isEndedAndMostVoted
      })}
    >
      <Row
        className={classNames(
          'items-center gap-[6px] py-[10px]',
          isSmall ? 'h-[40px]' : 'h-[48px]'
        )}
      >
        <Row className="grow items-start gap-[6px]">
          {!isEnded &&
            (!isVoted || (isVoted && isSelected)) &&
            checked !== null && (
              <Checkbox
                checked={checked}
                disabled={isVoted && isSelected}
                onClick={onClick}
              />
            )}

          {isEndedAndMostVoted && (
            <CheckIcon className="mr-[-4px] h-[24px] w-[24px] fill-primary-100 stroke-primary-100" />
          )}

          <Column className="grow gap-[4px]">
            <span
              className={`text-[16px] leading-[24px] tracking-[-0.32px] ${
                isEndedAndMostVoted ? 'text-primary-200' : 'text-black-200'
              }`}
            >
              {option.content}
            </span>
            {showProgressing && (
              <span className="text-[13px] leading-[1.38] tracking-[-0.26px] text-gray-600">
                {voteCount} people
              </span>
            )}
          </Column>
        </Row>

        {option.image_url != null && option.image_url !== '' && (
          <VoteOptionImage
            src={option.image_url}
            onClick={onClickImage}
            disabled={disabledImage}
            size={isSmall ? 'small' : 'large'}
          />
        )}
      </Row>
      {showProgressing && <VoteRate voteRate={voteRate} />}
    </Column>
  )
}

export default VoteOption
