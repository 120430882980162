import React, { useCallback, useEffect, useRef, useState } from 'react'

import Button from '@components/button/Button'
import { Dayjs } from 'dayjs'
import { debounce } from 'lodash'
import { Option } from 'types'

interface TimeSelectorProps {
  initDate: Dayjs
  options: Option[]
  selectedOption: Option
  onChange: (option: Option) => void
  itemHeight: number
  itemSpacing: number
}

const TimeSelector: React.FC<TimeSelectorProps> = props => {
  const {
    //
    initDate,
    options,
    selectedOption,
    onChange,
    itemHeight,
    itemSpacing
  } = props
  const containerRef = useRef<HTMLDivElement | null>(null)

  const debouncedHandleScroll = debounce(() => {
    if (containerRef.current == null) return
    const scrollOffset = containerRef.current.scrollTop
    const index = Math.round(scrollOffset / itemHeight)
    onChange(options[index])
  }, 100)

  const [isDragging, setIsDragging] = useState(false)
  const [startY, setStartY] = useState(0)
  const [scrollStart, setScrollStart] = useState(0)

  const handleMouseDown = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (containerRef.current == null) return
      setIsDragging(true)
      setStartY(e.clientY)
      setScrollStart(containerRef.current.scrollTop)
    },
    [containerRef.current]
  )
  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!isDragging) return
    if (containerRef.current == null) return
    const deltaY = startY - e.clientY
    containerRef.current.scrollTop = scrollStart + deltaY
  }
  const handleMouseUp = useCallback(() => {
    setIsDragging(false)
  }, [])
  const handleMouseLeave = useCallback(() => {
    setIsDragging(false)
  }, [])

  useEffect(() => {
    if (containerRef.current === null) return
    const scrollIndex = options.findIndex(
      option => option.value === selectedOption.value
    )
    containerRef.current?.scrollTo({
      top: scrollIndex * itemHeight,
      behavior: 'smooth'
    })
  }, [initDate, selectedOption, itemHeight])

  useEffect(() => {
    if (containerRef.current === null) return
    const scrollIndex = options.findIndex(
      option => option.value === selectedOption.value
    )
    containerRef.current.scrollTop = scrollIndex * itemHeight
  }, [initDate])

  return (
    <div
      ref={containerRef}
      className="scrollbar-hide flex w-full snap-y snap-mandatory flex-col overflow-y-scroll py-[72px]"
      style={{
        height: `${itemHeight * itemSpacing}px`
      }}
      onScroll={debouncedHandleScroll}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseLeave}
    >
      {options.map(option => {
        return (
          <Button
            key={option.value}
            className="shrink-0 snap-center items-center justify-center"
            style={{
              height: `${itemHeight}px`
            }}
            onClick={() => {
              onChange(option)
            }}
          >
            <span className="text-[16px] leading-[1.5] tracking-[-0.32px] text-black-100">
              {option.label}
            </span>
          </Button>
        )
      })}
    </div>
  )
}

export default TimeSelector
