import React, { MouseEventHandler } from 'react'

import { ReactComponent as CheckIcon } from '@assets/icons/ic-check.svg'
import Button from '@components/button/Button'

interface CheckboxProps {
  checked: boolean
  onClick?: MouseEventHandler<HTMLButtonElement>
  label?: string
  disabled?: boolean
}

const Checkbox: React.FC<CheckboxProps> = props => {
  const { label, checked, onClick, disabled = false } = props

  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      className={`h-[24px] w-[24px] items-center justify-center rounded-full border border-gray-500  ${
        checked
          ? 'border-none bg-primary-100 hover:outline-primary-100/[0.3]'
          : 'bg-white'
      } ${
        disabled
          ? 'cursor-not-allowed opacity-[0.3]'
          : 'hover:outline hover:outline-[3px] hover:outline-gray-300'
      }`}
    >
      <CheckIcon
        className={`h-[24px] w-[24px] stroke-none ${
          checked ? 'fill-white' : 'fill-gray-500'
        }`}
      />
      {label != null && <span>{label}</span>}
    </Button>
  )
}

export default Checkbox
