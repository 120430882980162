export const enum PostMessagePath {
  Editor = 'superclub/editor',
  Vote = 'superclub/vote',
  Superclub = 'superclub',
  Chat = 'superclub/chat'
}

export const enum ResponseCommand {
  Initialize = 'initialize',
  CreateVote = 'create/vote',
  ResetVote = 'reset/vote',
  MeasureHeight = 'measure/height'
}

export const enum RequestCommand {
  CreateSuccess = 'create/vote/success',
  CreateFailure = 'create/vote/failure',
  EditSuccess = 'edit/vote/success',
  EditFailure = 'edit/vote/failure',
  StopVote = 'stop/vote',
  MeasureHeightSuccess = 'measure/height/success',
  Ready = 'ready',
  ClickVoters = 'click/voters',
  VoteSuccess = 'vote/success',
  ClickVoteOptionImage = 'click/vote_option_image',
  ClickVoteOnPreview = 'click/vote/on_preview',
  NavigateVoteList = 'navigate/vote_list',
  NavigateBack = 'navigate/back'
}
