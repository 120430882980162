// import { RefObject } from 'react'

// import useEventListener from './useEventListener'

// type Handler = (event: MouseEvent) => void

// function useOnClickOutside<T extends HTMLElement = HTMLElement>(
//   ref: RefObject<T>,
//   handler: Handler,
//   mouseEvent: 'mousedown' | 'mouseup' = 'mousedown'
// ): void {
//   useEventListener(mouseEvent, event => {
//     const el = ref?.current

//     // Do nothing if clicking ref's element or descendent elements
//     if (el == null || el.contains(event.target as Node)) {
//       return
//     }

//     handler(event)
//   })
// }

// export default useOnClickOutside
import { useEffect, useRef, useCallback, useState } from 'react'

export const useOnClickOutside = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const ref = useRef<HTMLDivElement>(null)

  const handleClick = useCallback(
    (e: MouseEvent) => {
      const inside = ref.current?.contains(e.target as Node) ?? false
      if (inside) return
      setIsOpen(false)
    },
    [ref]
  )

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClick)
    }

    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [isOpen, handleClick])

  return { isOpen, setIsOpen, ref }
}
