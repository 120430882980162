import React from 'react'

interface SwitchProps {
  checked: boolean
  onChange: (checked: boolean) => void
  disabled?: boolean
}

const Switch: React.FC<SwitchProps> = props => {
  const { checked, onChange, disabled = false } = props
  return (
    <div className="flex">
      <label
        className={`flex ${
          disabled ? 'cursor-not-allowed opacity-30' : 'cursor-pointer'
        }`}
      >
        <input
          type="checkbox"
          className="sr-only"
          checked={checked}
          onChange={() => {
            onChange(!checked)
          }}
          disabled={disabled}
        />
        <div
          className={`flex h-7 w-[50px] items-center rounded-full border  px-[3px] transition duration-300 ${
            checked
              ? 'border-primary-200 bg-primary-100'
              : 'border-gray-400 bg-gray-300'
          }`}
        >
          <div
            className={`h-[22px] w-[22px] rounded-full bg-white shadow transition-transform duration-300 ease-in-out ${
              checked ? 'translate-x-[22px]' : ''
            }`}
          />
        </div>
      </label>
    </div>
  )
}

export default Switch
