import React, { useMemo, useRef } from 'react'

import { VoteInterface, VoteStatus } from '@api/voteApi'
import Column from '@components/common/Column'
import Space from '@components/common/Space'
import VoteOption from '@pages/Vote/components/VoteOption/VoteOption'
import { contextState } from '@store/atoms/contextState'
import dayjs from 'dayjs'
import { useVoteQuery } from 'hooks/query/useVoteQuery'
import useMeasureHeight from 'hooks/useMeasureHeight'
import usePostMessage from 'hooks/usePostMessage'
import { useParams } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { RequestCommand } from 'types/postMessageCommand'

const ChatMessage: React.FC = () => {
  const { voteId: _voteId, isEnded } = useParams()

  const voteId = Number(_voteId)
  const voteQuery = useVoteQuery(voteId)
  const vote = voteQuery.data?.data

  if (vote == null) return null
  return (
    <ChatMessagePresenter
      vote={vote}
      messageState={isEnded === 'true' ? 'ENDED' : 'VOTING'}
    />
  )
}

export default ChatMessage

const ChatMessagePresenter: React.FC<{
  vote: VoteInterface
  messageState?: VoteStatus
}> = props => {
  const { vote } = props
  const contextData = useRecoilValue(contextState)

  const ref = useRef<HTMLDivElement>(null)

  const postMessage = usePostMessage()

  useMeasureHeight(ref, height => {
    postMessage(RequestCommand.MeasureHeightSuccess, { data: { height } })
  })

  const voteSettingSummary: string = useMemo(() => {
    const list: string[] = []
    if (vote == null) return ''

    if (vote.status === 'ENDED' && vote.ended_at != null)
      return `Ended ${dayjs(vote.ended_at).format('MMM D, YYYY [at] HH:mm')}`
    if (vote.is_secret) list.push('Secret Poll')

    switch (true) {
      case vote.max_options === 1:
        break
      case vote.max_options === vote.options.length:
        list.push('All Selectable')
        break
      default:
        list.push(`Up to ${vote.max_options} Selectable`)
        break
    }

    return list.join(' • ')
  }, [vote])

  const isEnded = contextData?.voteMessageStatus === 'ENDED'

  return (
    <div ref={ref}>
      <Column className="shrink-0 px-[16px]">
        <span className="text-[14px] font-medium leading-[1.43] tracking-[-0.28px]">
          {vote.title}
        </span>
        <Space height={4} />

        <span className="text-[13px] font-medium leading-[1.38] tracking-[-0.26px] text-gray-500">
          {voteSettingSummary}
        </span>

        <Space height={24} />
        <Column className="gap-[24px]">
          {vote.options.map(option => (
            <VoteOption
              key={option.id}
              option={option}
              isVoted={isEnded}
              isEnded={isEnded}
              showProgressing={isEnded}
              isMostVoted={vote.most_voted_options?.includes?.(option.id)}
              disabledImage
              isSmall
            />
          ))}
        </Column>
        <Space height={12} />
      </Column>
    </div>
  )
}
