import { useEffect } from 'react'

const useMeasureHeight = (
  ref: React.RefObject<HTMLDivElement>,
  callback: (height: number) => void
) => {
  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      const { height } = entries[0].contentRect
      if (height != null) {
        callback(height)
      }
    })

    if (ref.current != null) {
      resizeObserver.observe(ref.current)
    }

    return () => {
      if (ref.current != null) {
        resizeObserver.unobserve(ref.current)
      }
    }
  }, [])
}

export default useMeasureHeight
