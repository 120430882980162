import React, { Dispatch, SetStateAction, useCallback, useMemo } from 'react'

import { Dayjs } from 'dayjs'
import { Option } from 'types'

import TimeSelector from './components/TimeSelector'

interface MinuteSelectorProps {
  initDate: Dayjs
  uiDate: Dayjs
  setUiDate: Dispatch<SetStateAction<Dayjs>>
  itemHeight: number
  itemSpacing: number
}

const MinuteSelector: React.FC<MinuteSelectorProps> = props => {
  const { initDate, uiDate, setUiDate, itemHeight, itemSpacing } = props

  const options: Option[] = useMemo(
    () =>
      Array.from({ length: 60 }, (_, i) => ({ value: i, label: i.toString() })),
    []
  )

  const selectedOption: Option = useMemo(
    () => ({
      value: uiDate.minute(),
      label: uiDate.minute().toString()
    }),
    [uiDate]
  )

  const onChange = useCallback((option: Option) => {
    setUiDate(prev => prev.minute(+option.value))
  }, [])

  return (
    <TimeSelector
      initDate={initDate}
      options={options}
      selectedOption={selectedOption}
      onChange={onChange}
      itemHeight={itemHeight}
      itemSpacing={itemSpacing}
    />
  )
}

export default MinuteSelector
