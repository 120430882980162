import React, { useMemo, useState } from 'react'

import voteApi, { VoteViewResult } from '@api/voteApi'
import Column from '@components/common/Column'
import DividedLine from '@components/common/DividedLine'
import Row from '@components/common/Row'
import Space from '@components/common/Space'
import DatePicker from '@components/date-picker/DatePicker'
import RadioGroup from '@components/radio/RadioGroup'
import TimePicker from '@components/time-picker/TimePicker'
import VoteLayout from '@components/vote/VoteLayout'
import dayjs from 'dayjs'
import usePostMessage from 'hooks/usePostMessage'
import usePostMessageListener from 'hooks/usePostMessageListener'
import { useMutation } from 'react-query'
import { Option } from 'types'
import { RequestCommand, ResponseCommand } from 'types/postMessageCommand'

import AddAnswerButton from './components/AddAnswerButton'
import AnswerCountDropdown from './components/AnswerCountDropdown'
import VoteInput from './components/VoteInput'
import VoteLabel from './components/VoteLabel'
import VoteLabelWithSwitch from './components/VoteLabelWithSwitch'

export interface VoteAnswer {
  id?: number
  content: string
  image_url: string // url string
}

const Create = () => {
  const [title, setTitle] = useState('')

  const initialAnswer: VoteAnswer = useMemo(
    () => ({ content: '', image_url: '' }),
    []
  )
  const initialAnswerList = useMemo(
    () => Array.from({ length: 2 }, () => ({ ...initialAnswer })),
    []
  )
  const [answerList, setAnswerList] = useState<VoteAnswer[]>(initialAnswerList)
  const validAnswerList: VoteAnswer[] = useMemo(
    () =>
      answerList.filter(
        answer => answer.content !== '' || answer.image_url !== ''
      ),
    [answerList]
  )

  const [isMultipleAnswers, setIsMultipleAnswers] = useState(false)

  const multipleAnswerOptionList: Option[] = useMemo(
    () => [
      { value: 2, label: '2' },
      ...Array.from({ length: validAnswerList.length - 2 }, (_, index) => ({
        value: index + 3,
        label: `${index + 3}`
      })),
      { value: validAnswerList.length + 1, label: 'no limit' }
    ],
    [validAnswerList.length]
  )
  const [multipleAnswerOption, setMultipleAnswerOption] = useState<Option>(
    multipleAnswerOptionList[0]
  )

  const [isSecret, setIsSecret] = useState(false)

  const viewOptionList: Array<Option<VoteViewResult>> = [
    { value: 'INSTANT', label: 'After voting' },
    { value: 'CLOSED', label: 'After voting ends' },
    { value: 'ALWAYS', label: 'Always view' }
  ]
  const [viewOption, setViewOption] = useState<Option>(viewOptionList[0])

  const [hasPeriod, setHasPeriod] = useState(false)
  const now = useMemo(() => dayjs(), [])
  const initialDate = useMemo(() => now.add(1, 'day'), [])
  const [date, setDate] = useState(initialDate)
  const [currentTime, setCurrentTime] = useState(initialDate)

  const [errors, setErrors] = useState({
    title: '',
    atLeastAnswer: '',
    date: ''
  })

  const validateTitle = () => {
    if (title.length === 0) {
      setErrors(prevState => ({
        ...prevState,
        title: 'Please type a question'
      }))
      return false
    } else {
      setErrors(prevState => ({ ...prevState, title: '' }))
      return true
    }
  }

  const validateAtLeastAnswer = () => {
    // 2개 이상의 answer가 존재해야 함
    if (validAnswerList.length > 1) return true

    setErrors(prevState => ({
      ...prevState,
      atLeastAnswer: 'At least 2 answers must be registered.'
    }))
    return false
  }

  const validateDate = () => {
    if (!hasPeriod) {
      setErrors(prevState => ({ ...prevState, date: '' }))
      return true
    }
    const regionNow = dayjs().add(60, 'second')
    const standard = date.hour(currentTime.hour()).minute(currentTime.minute())
    if (standard.isBefore(regionNow)) {
      setErrors(prevState => ({
        ...prevState,
        date: 'End date must be at least 60 seconds later.'
      }))
      return false
    }

    setErrors(prevState => ({ ...prevState, date: '' }))
    return true
  }

  const postMessage = usePostMessage()
  const mutation = useMutation(voteApi.postVote, {
    onSuccess: data => {
      postMessage(RequestCommand.CreateSuccess, { data: data.data })
    },
    onError: error => {
      postMessage(RequestCommand.CreateFailure, { data: error })
      console.log(error)
    }
  })

  const createVote = (event: MessageEvent) => {
    // 임시..
    const channelUrl = event.data?.channelUrl

    const voteData = {
      title,
      max_options: isMultipleAnswers ? +multipleAnswerOption.value : 1,
      expiry_date: hasPeriod
        ? date.hour(currentTime.hour()).minute(currentTime.minute())
        : undefined,
      is_secret: isSecret,
      view_result: viewOption.value,
      options: validAnswerList,
      ...(channelUrl != null && { channel_url: channelUrl })
    }

    const isTitleValid = validateTitle()
    const isAtLeastAnswerValid = validateAtLeastAnswer()
    const isDateValid = validateDate()
    if (isTitleValid && isAtLeastAnswerValid && isDateValid) {
      mutation.mutate({ data: voteData })
    }
  }

  const resetVote = () => {
    setTitle('')
    setAnswerList(Array.from({ length: 2 }, () => ({ ...initialAnswer })))
    setIsMultipleAnswers(false)
    setMultipleAnswerOption({ value: 2, label: '2' })
    setIsSecret(false)
    setViewOption(viewOptionList[0])
    setHasPeriod(false)
    setDate(initialDate)
    setCurrentTime(initialDate)
    setErrors({
      title: '',
      atLeastAnswer: '',
      date: ''
    })
  }

  const commandMap = new Map([
    [ResponseCommand.CreateVote, createVote],
    [ResponseCommand.ResetVote, resetVote]
  ])
  usePostMessageListener(commandMap)

  return (
    <VoteLayout
      hideHeader
      hideFooter
      //
    >
      {/* TOPIC */}
      <VoteLabel>Topic</VoteLabel>
      <Space height={12} />
      <VoteInput
        value={title}
        onChange={e => {
          setTitle(e.target.value)
          setErrors(prevState => ({ ...prevState, title: '' }))
        }}
        placeholder="Type your question here"
        maxLength={100}
        errorMessages={errors.title}
        onBlur={validateTitle}
      />
      <Space height={24} />
      {/* TYPE */}
      <VoteLabel>Type</VoteLabel>

      {answerList.map((answerDetail, index) => {
        const onChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
          setAnswerList(prevState => {
            const newState = [...prevState]
            newState[index].content = e.target.value
            return newState
          })
          setErrors(prevState => ({ ...prevState, atLeastAnswer: '' }))
        }

        const onChangeImage = (source: string) => {
          setAnswerList(prevState => {
            const newState = [...prevState]
            newState[index].image_url = source
            return newState
          })
        }

        return (
          <Column key={index}>
            <Space height={12} />
            <VoteInput
              value={answerDetail.content}
              onChange={onChangeValue}
              placeholder="Type your answers here"
              maxLength={50}
              image={answerDetail.image_url}
              setImage={onChangeImage}
              onBlur={() => {
                if (index === answerList.length - 1) validateAtLeastAnswer()
              }}
            />
          </Column>
        )
      })}

      {errors.atLeastAnswer !== '' && (
        <span className="mt-[4px] text-[13px] leading-[1.38] tracking-[-0.26px] text-error-100">
          {errors.atLeastAnswer}
        </span>
      )}
      <Space height={12} />
      <AddAnswerButton
        className="self-end"
        onClick={() => {
          setAnswerList(prevState => [...prevState, { ...initialAnswer }])
        }}
      />
      <Space height={16} />
      <DividedLine />
      {/* MULTIPLE ANSWER */}
      <Space height={16} />
      <VoteLabelWithSwitch
        label="Select multiple answers"
        checked={isMultipleAnswers}
        onChange={setIsMultipleAnswers}
      />

      {isMultipleAnswers && (
        <Column>
          <Space height={12} />
          <Column className="w-[240px]">
            <AnswerCountDropdown
              optionList={multipleAnswerOptionList}
              selectedOption={multipleAnswerOption}
              onChange={option => {
                setMultipleAnswerOption(option)
              }}
            />
          </Column>
          <Space height={8} />
        </Column>
      )}
      <Space height={16} />
      <DividedLine />

      {/* SECRET POLL */}
      <Space height={16} />
      <VoteLabelWithSwitch
        label="Secret poll"
        checked={isSecret}
        onChange={setIsSecret}
      />
      <Space height={16} />
      <DividedLine />

      {/* VIEW RESULT */}
      <Space height={16} />
      <VoteLabel>View results</VoteLabel>
      <Space height={12} />
      <RadioGroup
        groupName="view_result"
        optionList={viewOptionList}
        selectedOption={viewOption}
        onChange={option => {
          setViewOption(option)
        }}
      />
      <Space height={16} />
      <DividedLine />
      {/* END DATE */}
      <Space height={16} />
      <VoteLabelWithSwitch
        label="End date"
        checked={hasPeriod}
        onChange={setHasPeriod}
      />
      <Space height={12} />

      {hasPeriod && (
        <>
          <Column>
            <Row className="gap-[4px]">
              <DatePicker date={date} onChange={setDate} />
              <TimePicker
                date={currentTime}
                onChange={setCurrentTime}
                validError={errors.date !== ''}
                isDateBeforeNow={date.isSameOrBefore(now)}
              />
            </Row>
            {errors.date !== '' && (
              <span className="mt-[4px] self-end text-[13px] leading-[1.38] tracking-[-0.26px] text-error-100">
                {errors.date}
              </span>
            )}
          </Column>
          <Space height={12} />
        </>
      )}
      <Space height={12} />

      {/* {process.env.NODE_ENV === 'development' && (
        <JButton label="hello" onClick={createVote} />
      )} */}
    </VoteLayout>
  )
}

export default Create
