import React from 'react'

import Row from '@components/common/Row'
import ProgressBar from '@components/progress/ProgressBar'

interface VoteRateProps {
  voteRate: number
}

const VoteRate: React.FC<VoteRateProps> = props => {
  const { voteRate } = props
  return (
    <Row className="items-center gap-[12px]">
      <Row className="w-[510px] items-center">
        <ProgressBar rate={voteRate} />
      </Row>

      <span className="shrink-0 text-[14px] font-medium leading-[1.43] tracking-[-0.28px] text-primary-100">
        {voteRate}%
      </span>
    </Row>
  )
}

export default VoteRate
