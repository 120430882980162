import { useCallback, useEffect } from 'react'

import { ResponseCommand } from 'types/postMessageCommand'

const usePostMessageListener = (
  commandMap: Map<ResponseCommand, (event: MessageEvent<any>) => void>
) => {
  const handler = useCallback(
    (event: MessageEvent) => {
      try {
        const command = event.data.command as ResponseCommand
        commandMap.forEach((func, commandKey) => {
          if (commandKey === command) {
            func(event)
          }
        })
      } catch (error) {
        console.error(error)
      }
    },
    [commandMap]
  )

  useEffect(() => {
    window.addEventListener('message', handler)

    return () => {
      window.removeEventListener('message', handler)
    }
  }, [handler])

  return null
}

export default usePostMessageListener
