import React, { Dispatch, SetStateAction, useCallback, useMemo } from 'react'

import { Dayjs } from 'dayjs'
import { Option } from 'types'

import TimeSelector from './components/TimeSelector'

interface AmPmSelectorProps {
  initDate: Dayjs
  uiDate: Dayjs
  setUiDate: Dispatch<SetStateAction<Dayjs>>
  itemHeight: number
  itemSpacing: number
}

const AmPmSelector: React.FC<AmPmSelectorProps> = props => {
  const { initDate, uiDate, setUiDate, itemHeight, itemSpacing } = props
  const ampmFormat = useMemo(() => uiDate.format('A'), [uiDate])

  const options = useMemo(
    () => [
      { value: 'AM', label: 'AM' },
      { value: 'PM', label: 'PM' }
    ],
    []
  )

  const selectedOption = useMemo(
    () => ({
      value: ampmFormat,
      label: ampmFormat
    }),
    [ampmFormat]
  )

  const onChange = useCallback((option: Option) => {
    setUiDate(prev => {
      if (option.value === 'AM') {
        return prev.hour(prev.hour() % 12)
      } else {
        return prev.hour((prev.hour() % 12) + 12)
      }
    })
  }, [])

  return (
    <TimeSelector
      initDate={initDate}
      options={options}
      selectedOption={selectedOption}
      onChange={onChange}
      itemHeight={itemHeight}
      itemSpacing={itemSpacing}
    />
  )
}

export default AmPmSelector
