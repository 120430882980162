import React from 'react'

import Column from '@components/common/Column'
import { Option } from 'types'

import DropdownOption from './DropdownOption'

interface DropdownContainerProps {
  selectedOption: Option
  optionList: Option[]
  onChange: (option: Option) => void
}

const DropdownContainer = (props: DropdownContainerProps) => {
  const { optionList, onChange, selectedOption } = props

  return (
    <Column className="absolute top-[60px] z-10 max-h-[260px] w-full overflow-y-auto rounded-[8px] border border-gray-500 bg-white py-[8px]">
      {optionList.map((option, index) => {
        return (
          <DropdownOption
            key={index}
            isSelected={option.value === selectedOption.value}
            {...option}
            onClick={() => {
              onChange(option)
            }}
          />
        )
      })}
    </Column>
  )
}

export default DropdownContainer
