import React, { forwardRef, useEffect } from 'react'

import styled from '@emotion/styled'
import { ContextStateInterface, contextState } from '@store/atoms/contextState'
import usePostMessage from 'hooks/usePostMessage'
import { Outlet } from 'react-router-dom'
import { useRecoilCallback } from 'recoil'
import {
  RequestCommand,
  PostMessagePath,
  ResponseCommand
} from 'types/postMessageCommand'

interface RootLayoutProps {
  children: React.ReactNode
}

const RootLayout = forwardRef<HTMLDivElement, RootLayoutProps>((props, ref) => {
  const { children } = props

  return <StyledDiv ref={ref}>{children}</StyledDiv>
})
RootLayout.displayName = 'RootLayout'

const Root = () => {
  const [isReady, setIsReady] = React.useState(false)
  const rootRef = React.useRef<HTMLDivElement>(null)

  const handler = useRecoilCallback(
    ({ set }) =>
      (event: MessageEvent) => {
        if (
          !event.origin.includes('localhost') &&
          !event.origin.includes('superclub')
        ) {
          return
        }

        if (
          typeof event.data !== 'object' ||
          !(
            event.data.from === PostMessagePath.Editor ||
            event.data.from === PostMessagePath.Superclub ||
            event.data.from === PostMessagePath.Chat
          )
        ) {
          return
        }

        // 외부에서 initialize 요청이 들어온 경우, accessToken을 저장하고 isReady를 true로 변경한다.
        // isReady가 true가 되면 Outlet이 렌더링
        if (event.data.command === ResponseCommand.Initialize) {
          const accessToken = event.data.accessToken as string
          const contextData = event.data.data as ContextStateInterface
          set(contextState, contextData)
          localStorage.setItem('accessToken', accessToken)
          setIsReady(true)
        }

        // // todo..
        // if (event.data.from === PostMessagePath.Chat) {
        //   console.log(snapshot)
        //   // temp
        //   console.log('vote receive')
        //   console.log('event', event)

        //   console.log('event.data', event.data)
        //   console.log('event.origin', event.origin)
        //   // window?.frames?.postMessage('Hello from child', '*')
        //   // if (window.parent.origin)
        //   const data = event.data.data as User
        //   const accessToken = event.data.accessToken as string
        //   const command = event.data.command as string
        //   set(accessTokenState, accessToken)
        //   set(userState, data)

        // }
      },
    []
  )

  const postMessage = usePostMessage()

  useEffect(() => {
    window.addEventListener('message', handler)

    postMessage(RequestCommand.Ready)
    return () => {
      window.removeEventListener('message', handler)
    }
  }, [])

  if (process.env.NODE_ENV === 'development')
    return (
      <RootLayout ref={rootRef}>
        <Outlet />
      </RootLayout>
    )

  return <RootLayout ref={rootRef}>{isReady && <Outlet />}</RootLayout>
}

export default Root

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: fixed;
  inset: 0px;
`
