import React from 'react'

import { ReactComponent as CheckIcon } from '@assets/icons/ic-check.svg'
import Button from '@components/button/Button'
import { Option } from 'types'

export interface DropdownOptionProps extends Option {
  isSelected: boolean
  onClick: () => void
  icon?: React.ReactNode
}

const DropdownOption = (props: DropdownOptionProps) => {
  const { isSelected = false, label, onClick, icon } = props

  return (
    <Button
      onClick={onClick}
      className={`flex h-[48px] shrink-0 items-center justify-between pl-[15px] pr-[11px] hover:bg-gray-100
        ${isSelected ? 'bg-gray-100' : 'bg-white'}
      `}
    >
      {icon ?? null}
      <span
        className={`text-[16px] leading-[1.5] tracking-[-0.32px] 
        ${isSelected ? 'text-primary-100' : 'text-black-100'}`}
      >
        {label}
      </span>
      {isSelected && (
        <CheckIcon className="fill-primary-100 stroke-primary-100" />
      )}
    </Button>
  )
}

export default DropdownOption
