import React from 'react'

import styled from '@emotion/styled'

interface ColumnProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode
  className?: React.HTMLAttributes<HTMLDivElement>['className']
}

const Column: React.FC<ColumnProps> = (props: ColumnProps) => {
  const { children, className = '', ...restProps } = props
  return (
    <StyledColumn className={className} {...restProps}>
      {children}
    </StyledColumn>
  )
}
export default Column

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
`
