import React from 'react'

import { VoterInterface } from '@api/voteApi'
import Button from '@components/button/Button'
import Center from '@components/common/Center'
import Row from '@components/common/Row'
import Space from '@components/common/Space'
import CoverFitImage from '@components/image/CoverFitImage'
import { ReactComponent as DotIcon } from '@icons/ic-dotdotdot.svg'

interface VotersProps {
  voters: VoterInterface[]
  voteCount: number
  onClickMore?: () => void
}

const VOTER_MAX_COUNT = 15

const Voters: React.FC<VotersProps> = props => {
  const { voters, voteCount, onClickMore } = props

  return (
    <>
      <Row>
        <span className="whitespace-pre text-[18px] font-medium leading-[1.44] tracking-[-0.36px] text-black-200">
          Participated by{' '}
        </span>
        <Button onClick={onClickMore}>
          <span className=" text-[18px] font-semibold leading-[1.44] tracking-[-0.36px] text-black-200 underline">
            {`${voteCount}`} voters
          </span>
        </Button>
      </Row>
      <Space height={12} />
      <Row>
        {voters.slice(0, VOTER_MAX_COUNT).map((voterItem, index) => {
          return (
            <Button
              key={index}
              className={`relative h-[48px] w-[48px] shrink-0 overflow-hidden rounded-full border-[2px] border-white ${
                index !== 0 ? 'ml-[-12px]' : ''
              }`}
              style={{ zIndex: -index }}
              disabled
              onClick={() => {}}
            >
              <CoverFitImage
                className={`h-full w-full`}
                src={voterItem.user.profile_image_url}
                alt="voter_profile_image"
              />
              {index === VOTER_MAX_COUNT - 1 && (
                <Center className="absolute inset-0 bg-black-0/50">
                  <DotIcon className="fill-white" />
                </Center>
              )}
            </Button>
          )
        })}
        <Button>
          <img></img>
        </Button>
      </Row>
    </>
  )
}

export default Voters
