import React, { useMemo } from 'react'

import { Option } from 'types'

interface RadioOptionProps {
  option: Option
  groupName: string
  isSelected: boolean
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const RadioOption: React.FC<RadioOptionProps> = props => {
  const { groupName, option, isSelected, onChange } = props
  const id = useMemo(
    () => `${groupName}-${String(option.value)}`,
    [groupName, option.value]
  )

  return (
    <>
      <input
        className="sr-only"
        type="radio"
        id={id}
        name={groupName}
        value={option.value}
        checked={isSelected}
        onChange={onChange}
      />
      <label
        className="group/radio_btn flex cursor-pointer items-center gap-[10px]"
        htmlFor={id}
      >
        <div
          className={`h-[24px] w-[24px] rounded-full border bg-white transition-[border-width] group-hover/radio_btn:outline group-hover/radio_btn:outline-[3px] group-focus/radio_btn:outline group-focus/radio_btn:outline-[3px]
          ${
            isSelected
              ? 'border-[7px] border-primary-100 group-hover/radio_btn:outline-primary-100/[0.3] group-focus/radio_btn:outline-primary-100/[0.3]'
              : 'border-[1px] border-gray-500 group-hover/radio_btn:outline-gray-400 group-focus/radio_btn:outline-gray-400'
          }`}
        />
        {option.label}
      </label>
    </>
  )
}

export default RadioOption
