import React from 'react'

import styled from '@emotion/styled'

interface SpaceProps extends React.HTMLAttributes<HTMLDivElement> {
  width?: number
  height?: number
  className?: string
}

const Space: React.FC<SpaceProps> = (props: SpaceProps) => {
  const { width, height, className = '', ...restProps } = props
  return (
    <StyledSpace
      className={className}
      width={width}
      height={height}
      {...restProps}
    />
  )
}

export default Space

const StyledSpace = styled.div<{ width?: number; height?: number }>`
  display: flex;
  flex-shrink: 0;
  ${({ width }) => width !== undefined && `width: ${width}px;`}
  ${({ height }) => height !== undefined && `height: ${height}px;`}
`
