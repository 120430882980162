// import axiosDefault from 'axios'
// import _ from 'lodash'

import axios from './axios.config'
import { ApiProps } from './voteApi'

const commonBaseUrl: string = process.env.REACT_APP_COMMON_API_URL ?? ''

const getActivities = async (props: ApiProps) =>
  await axios.get(`${commonBaseUrl}/v1/activities`, { params: props?.params })
const postActivity = async (props: ApiProps) =>
  await axios.post(`${commonBaseUrl}/v1/activity`, props?.data)
const postActivityLog = async (props: ApiProps) =>
  await axios.post(`${commonBaseUrl}/v1/activity-log`, props?.data)
const deleteActivityLog = async (props: ApiProps) =>
  await axios.delete(`${commonBaseUrl}/v1/activity-log/${props.id as number}`, {
    params: props?.params
  })
const getActivityLogs = async (props: ApiProps) =>
  await axios.get(`${commonBaseUrl}/v1/activity-logs`, {
    params: props?.params
  })
const postAdminUserLogin = async (props: ApiProps) =>
  await axios.post(`${commonBaseUrl}/v1/admin/user/login`, props?.data)
const postAdminUserSignup = async (props: ApiProps) =>
  await axios.post(`${commonBaseUrl}/v1/admin/user/signup`, props?.data)
const patchAdminUserPassword = async (props: ApiProps) =>
  await axios.patch(
    `${commonBaseUrl}/v1/admin/user/${props.id as number}/password`,
    props?.data
  )
const postAlarm = async (props: ApiProps) =>
  await axios.post(`${commonBaseUrl}/v1/alarm`, props?.data)
const patchAlarm = async (props: ApiProps) =>
  await axios.patch(
    `${commonBaseUrl}/v1/alarm/${props.id as number}`,
    props?.data
  )
const deleteAlarm = async (props: ApiProps) =>
  await axios.delete(`${commonBaseUrl}/v1/alarm/${props.id as number}`, {
    params: props?.params
  })
const getAlarms = async (props: ApiProps) =>
  await axios.get(`${commonBaseUrl}/v1/alarms`, { params: props?.params })
const getBadges = async (props: ApiProps) =>
  await axios.get(`${commonBaseUrl}/v1/badges`, { params: props?.params })
const postBookmark = async (props: ApiProps) =>
  await axios.post(`${commonBaseUrl}/v1/bookmark`, props?.data)
const deleteBookmark = async (props: ApiProps) =>
  await axios.delete(`${commonBaseUrl}/v1/bookmark`, { params: props?.params })
const getBookmarks = async (props: ApiProps) =>
  await axios.get(`${commonBaseUrl}/v1/bookmarks`, { params: props?.params })
const patchCertificate = async (props: ApiProps) =>
  await axios.patch(
    `${commonBaseUrl}/v1/certificate/${props.id as number}`,
    props?.data
  )
const getFaq = async (props: ApiProps) =>
  await axios.get(`${commonBaseUrl}/v1/faq/${props.id as number}`, {
    params: props?.params
  })
const getFaqs = async (props: ApiProps) =>
  await axios.get(`${commonBaseUrl}/v1/faqs`, { params: props?.params })
const getFaqsCategories = async (props: ApiProps) =>
  await axios.get(`${commonBaseUrl}/v1/faqs-categories`, {
    params: props?.params
  })
const postFriendRequestsApproval = async (props: ApiProps) =>
  await axios.post(`${commonBaseUrl}/v1/friend-requests/approval`, props?.data)
const postFriendRequestsRefusal = async (props: ApiProps) =>
  await axios.post(`${commonBaseUrl}/v1/friend-requests/refusal`, props?.data)
const getGrades = async (props: ApiProps) =>
  await axios.get(`${commonBaseUrl}/v1/grades`, { params: props?.params })
const postHeartHide = async (props: ApiProps) =>
  await axios.post(
    `${commonBaseUrl}/v1/heart/${props.id as number}/hide`,
    props?.data
  )
const postHeartShow = async (props: ApiProps) =>
  await axios.post(
    `${commonBaseUrl}/v1/heart/${props.id as number}/show`,
    props?.data
  )
const getLabels = async (props: ApiProps) =>
  await axios.get(`${commonBaseUrl}/v1/labels`, { params: props?.params })
const postMediaFile = async (props: ApiProps) =>
  await axios.post(`${commonBaseUrl}/v1/media/file`, props?.data)
const getMediaFile = async (props: ApiProps) =>
  await axios.get(`${commonBaseUrl}/v1/media/file/${props.id as number}`, {
    params: props?.params
  })
const postMediaGif = async (props: ApiProps) =>
  await axios.post(`${commonBaseUrl}/v1/media/gif`, props?.data)
const getMediaGif = async (props: ApiProps) =>
  await axios.get(`${commonBaseUrl}/v1/media/gif/${props.id as number}`, {
    params: props?.params
  })
const postMediaImage = async (props: ApiProps) =>
  await axios.post(`${commonBaseUrl}/v1/media/image`, props?.data)
const getMediaImage = async (props: ApiProps) =>
  await axios.get(`${commonBaseUrl}/v1/media/image/${props.id as number}`, {
    params: props?.params
  })
const postMediaVideo = async (props: ApiProps) =>
  await axios.post(`${commonBaseUrl}/v1/media/video`, props?.data)
const getMediaVideo = async (props: ApiProps) =>
  await axios.get(`${commonBaseUrl}/v1/media/video/${props.id as number}`, {
    params: props?.params
  })
const getNoticeCategories = async (props: ApiProps) =>
  await axios.get(`${commonBaseUrl}/v1/notice-categories`, {
    params: props?.params
  })
const getNotice = async (props: ApiProps) =>
  await axios.get(`${commonBaseUrl}/v1/notice/${props.id as number}`, {
    params: props?.params
  })
const getNotices = async (props: ApiProps) =>
  await axios.get(`${commonBaseUrl}/v1/notices`, { params: props?.params })
const postPing = async (props: ApiProps) =>
  await axios.post(`${commonBaseUrl}/v1/ping`, props?.data)
const getPolicyVersion = async (props: ApiProps) =>
  await axios.get(`${commonBaseUrl}/v1/policy-version/${props.id as number}`, {
    params: props?.params
  })
const getPolicyVersions = async (props: ApiProps) =>
  await axios.get(`${commonBaseUrl}/v1/policy-versions`, {
    params: props?.params
  })
const postQuestion = async (props: ApiProps) =>
  await axios.post(`${commonBaseUrl}/v1/question`, props?.data)
const getQuestionCategories = async (props: ApiProps) =>
  await axios.get(`${commonBaseUrl}/v1/question-categories`, {
    params: props?.params
  })
const getQuestion = async (props: ApiProps) =>
  await axios.get(`${commonBaseUrl}/v1/question/${props.id as number}`, {
    params: props?.params
  })
const patchQuestion = async (props: ApiProps) =>
  await axios.patch(
    `${commonBaseUrl}/v1/question/${props.id as number}`,
    props?.data
  )
const deleteQuestion = async (props: ApiProps) =>
  await axios.delete(`${commonBaseUrl}/v1/question/${props.id as number}`, {
    params: props?.params
  })
const getQuestions = async (props: ApiProps) =>
  await axios.get(`${commonBaseUrl}/v1/questions`, { params: props?.params })
const postReport = async (props: ApiProps) =>
  await axios.post(`${commonBaseUrl}/v1/report`, props?.data)
const postRespectHide = async (props: ApiProps) =>
  await axios.post(
    `${commonBaseUrl}/v1/respect/${props.id as number}/hide`,
    props?.data
  )
const postRespectShow = async (props: ApiProps) =>
  await axios.post(
    `${commonBaseUrl}/v1/respect/${props.id as number}/show`,
    props?.data
  )
const getRings = async (props: ApiProps) =>
  await axios.get(`${commonBaseUrl}/v1/rings`, { params: props?.params })
const getTermVersion = async (props: ApiProps) =>
  await axios.get(`${commonBaseUrl}/v1/term-version/${props.id as number}`, {
    params: props?.params
  })
const getTermVersions = async (props: ApiProps) =>
  await axios.get(`${commonBaseUrl}/v1/term-versions`, {
    params: props?.params
  })
const postToken = async (props: ApiProps) =>
  await axios.post(`${commonBaseUrl}/v1/token/`, props?.data)
const postTokenRefresh = async (props: ApiProps) =>
  await axios.post(`${commonBaseUrl}/v1/token/refresh/`, props?.data)
const postTokenVerify = async (props: ApiProps) =>
  await axios.post(`${commonBaseUrl}/v1/token/verify/`, props?.data)
const patchUserInfo = async (props: ApiProps) =>
  await axios.patch(
    `${commonBaseUrl}/v1/user-info/${props.id as number}`,
    props?.data
  )
const deleteUserInfo = async (props: ApiProps) =>
  await axios.delete(`${commonBaseUrl}/v1/user-info/${props.id as number}`, {
    params: props?.params
  })
const postUserInfoAgree = async (props: ApiProps) =>
  await axios.post(
    `${commonBaseUrl}/v1/user-info/${props.id as number}/agree`,
    props?.data
  )
const postUserInfoCertificate = async (props: ApiProps) =>
  await axios.post(
    `${commonBaseUrl}/v1/user-info/${props.id as number}/certificate`,
    props?.data
  )
const postUserInfoDisagree = async (props: ApiProps) =>
  await axios.post(
    `${commonBaseUrl}/v1/user-info/${props.id as number}/disagree`,
    props?.data
  )
const postUserInfoInfoUnagree = async (props: ApiProps) =>
  await axios.post(
    `${commonBaseUrl}/v1/user-info/${props.id as number}/info/unagree`,
    props?.data
  )
const postUserInfoInfoUndisagree = async (props: ApiProps) =>
  await axios.post(
    `${commonBaseUrl}/v1/user-info/${props.id as number}/info/undisagree`,
    props?.data
  )
const patchUserInfoOrder = async (props: ApiProps) =>
  await axios.patch(
    `${commonBaseUrl}/v1/user-info/${props.id as number}/order`,
    props?.data
  )
const getUserInfoCertificates = async (props: ApiProps) =>
  await axios.get(
    `${commonBaseUrl}/v1/user-info/${props.id as number}/certificates`,
    { params: props?.params }
  )
const postUserEmailAuth = async (props: ApiProps) =>
  await axios.post(`${commonBaseUrl}/v1/user/email/auth`, props?.data)
const postUserEmailSend = async (props: ApiProps) =>
  await axios.post(`${commonBaseUrl}/v1/user/email/send`, props?.data)
const getUserMe = async (props: ApiProps) =>
  await axios.get(`${commonBaseUrl}/v1/user/me`, { params: props?.params })
const postUserUnwithdraw = async (props: ApiProps) =>
  await axios.post(`${commonBaseUrl}/v1/user/unwithdraw`, props?.data)
const postUserWithdraw = async (props: ApiProps) =>
  await axios.post(`${commonBaseUrl}/v1/user/withdraw`, props?.data)
const getUser = async (props: ApiProps) =>
  await axios.get(`${commonBaseUrl}/v1/user/${props.id as number}`, {
    params: props?.params
  })
const patchUser = async (props: ApiProps) =>
  await axios.patch(
    `${commonBaseUrl}/v1/user/${props.id as number}`,
    props?.data
  )
const postUserBadge = async (props: ApiProps) =>
  await axios.post(
    `${commonBaseUrl}/v1/user/${props.id as number}/badge`,
    props?.data
  )
const patchUserBannerImage = async (props: ApiProps) =>
  await axios.patch(
    `${commonBaseUrl}/v1/user/${props.id as number}/banner-image`,
    props?.data
  )
const postUserBookmark = async (props: ApiProps) =>
  await axios.post(
    `${commonBaseUrl}/v1/user/${props.id as number}/bookmark`,
    props?.data
  )
const postUserFriendRequest = async (props: ApiProps) =>
  await axios.post(
    `${commonBaseUrl}/v1/user/${props.id as number}/friend-request`,
    props?.data
  )
const deleteUserFriendRequest = async (props: ApiProps) =>
  await axios.delete(
    `${commonBaseUrl}/v1/user/${props.id as number}/friend-request`,
    { params: props?.params }
  )
const postUserHeart = async (props: ApiProps) =>
  await axios.post(
    `${commonBaseUrl}/v1/user/${props.id as number}/heart`,
    props?.data
  )
const postUserHeartCheck = async (props: ApiProps) =>
  await axios.post(
    `${commonBaseUrl}/v1/user/${props.id as number}/heart/check`,
    props?.data
  )
const postUserInfo = async (props: ApiProps) =>
  await axios.post(
    `${commonBaseUrl}/v1/user/${props.id as number}/info`,
    props?.data
  )
const postUserLabel = async (props: ApiProps) =>
  await axios.post(
    `${commonBaseUrl}/v1/user/${props.id as number}/label`,
    props?.data
  )
const deleteUserLabel = async (props: ApiProps) =>
  await axios.delete(`${commonBaseUrl}/v1/user/${props.id as number}/label`, {
    params: props?.params
  })
const getUserLabels = async (props: ApiProps) =>
  await axios.get(`${commonBaseUrl}/v1/user/${props.id as number}/labels`, {
    params: props?.params
  })
const patchUserProfileImage = async (props: ApiProps) =>
  await axios.patch(
    `${commonBaseUrl}/v1/user/${props.id as number}/profile-image`,
    props?.data
  )
const postUserRespect = async (props: ApiProps) =>
  await axios.post(
    `${commonBaseUrl}/v1/user/${props.id as number}/respect`,
    props?.data
  )
const postUserResponseCheck = async (props: ApiProps) =>
  await axios.post(
    `${commonBaseUrl}/v1/user/${props.id as number}/response/check`,
    props?.data
  )
const postUserUnbookmark = async (props: ApiProps) =>
  await axios.post(
    `${commonBaseUrl}/v1/user/${props.id as number}/unbookmark`,
    props?.data
  )
const postUserUnfriend = async (props: ApiProps) =>
  await axios.post(
    `${commonBaseUrl}/v1/user/${props.id as number}/unfriend`,
    props?.data
  )
const postUserUnheart = async (props: ApiProps) =>
  await axios.post(
    `${commonBaseUrl}/v1/user/${props.id as number}/unheart`,
    props?.data
  )
const postUserUnrespect = async (props: ApiProps) =>
  await axios.post(
    `${commonBaseUrl}/v1/user/${props.id as number}/unrespect`,
    props?.data
  )
const getUserFriendRequests = async (props: ApiProps) =>
  await axios.get(
    `${commonBaseUrl}/v1/user/${props.id as number}/friend-requests`,
    { params: props?.params }
  )
const getUserFriends = async (props: ApiProps) =>
  await axios.get(`${commonBaseUrl}/v1/user/${props.id as number}/friends`, {
    params: props?.params
  })
const getUserHearts = async (props: ApiProps) =>
  await axios.get(`${commonBaseUrl}/v1/user/${props.id as number}/hearts`, {
    params: props?.params
  })
const getUserInfos = async (props: ApiProps) =>
  await axios.get(`${commonBaseUrl}/v1/user/${props.id as number}/infos`, {
    params: props?.params
  })
const getUserRespects = async (props: ApiProps) =>
  await axios.get(`${commonBaseUrl}/v1/user/${props.id as number}/respects`, {
    params: props?.params
  })
const getUsers = async (props: ApiProps) =>
  await axios.get(`${commonBaseUrl}/v1/users`, { params: props?.params })
const getWithdrawChoices = async (props: ApiProps) =>
  await axios.get(`${commonBaseUrl}/v1/withdraw-choices`, {
    params: props?.params
  })

export default {
  getActivities,
  postActivity,
  postActivityLog,
  deleteActivityLog,
  getActivityLogs,
  postAdminUserLogin,
  postAdminUserSignup,
  patchAdminUserPassword,
  postAlarm,
  patchAlarm,
  deleteAlarm,
  getAlarms,
  getBadges,
  postBookmark,
  deleteBookmark,
  getBookmarks,
  patchCertificate,
  getFaq,
  getFaqs,
  getFaqsCategories,
  postFriendRequestsApproval,
  postFriendRequestsRefusal,
  getGrades,
  postHeartHide,
  postHeartShow,
  getLabels,
  postMediaFile,
  getMediaFile,
  postMediaGif,
  getMediaGif,
  postMediaImage,
  getMediaImage,
  postMediaVideo,
  getMediaVideo,
  getNoticeCategories,
  getNotice,
  getNotices,
  postPing,
  getPolicyVersion,
  getPolicyVersions,
  postQuestion,
  getQuestionCategories,
  getQuestion,
  patchQuestion,
  deleteQuestion,
  getQuestions,
  postReport,
  postRespectHide,
  postRespectShow,
  getRings,
  getTermVersion,
  getTermVersions,
  postToken,
  postTokenRefresh,
  postTokenVerify,
  patchUserInfo,
  deleteUserInfo,
  postUserInfoAgree,
  postUserInfoCertificate,
  postUserInfoDisagree,
  postUserInfoInfoUnagree,
  postUserInfoInfoUndisagree,
  patchUserInfoOrder,
  getUserInfoCertificates,
  postUserEmailAuth,
  postUserEmailSend,
  getUserMe,
  postUserUnwithdraw,
  postUserWithdraw,
  getUser,
  patchUser,
  postUserBadge,
  patchUserBannerImage,
  postUserBookmark,
  postUserFriendRequest,
  deleteUserFriendRequest,
  postUserHeart,
  postUserHeartCheck,
  postUserInfo,
  postUserLabel,
  deleteUserLabel,
  getUserLabels,
  patchUserProfileImage,
  postUserRespect,
  postUserResponseCheck,
  postUserUnbookmark,
  postUserUnfriend,
  postUserUnheart,
  postUserUnrespect,
  getUserFriendRequests,
  getUserFriends,
  getUserHearts,
  getUserInfos,
  getUserRespects,
  getUsers,
  getWithdrawChoices
}
