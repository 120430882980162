import React, { useCallback, useRef } from 'react'

import Column from '@components/common/Column'
import CoverFitImage from '@components/image/CoverFitImage'
import FileInput from '@components/input/FileInput'
import Input from '@components/input/Input'
import { ReactComponent as PhotoIcon } from '@icons/ic-photo.svg'
import { ReactComponent as CloseIcon } from '@icons/ic-x.svg'

interface VoteInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  placeholder?: string
  value?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  maxLength?: number
  image?: string
  setImage?: (image: string) => void
  errorMessages?: string
  disabled?: boolean
}

const VoteInput: React.FC<VoteInputProps> = props => {
  const {
    //
    placeholder,
    value,
    onChange,
    maxLength,
    image,
    setImage,
    errorMessages,
    disabled = false,
    ..._props
  } = props

  const [isFocus, setIsFocus] = React.useState(false)

  const fileRef = useRef<HTMLInputElement>(null)

  const onClickFile = useCallback(() => {
    fileRef.current?.click()
  }, [])

  return (
    <Column>
      <div
        className={`group/image_input_container relative flex h-[52px] grow items-center rounded-[8px] border border-gray-300 px-[16px] transition ${
          isFocus ? 'focus-within:border-primary-100' : ''
        } ${errorMessages !== '' ? 'border-error-100' : ''} ${
          disabled ? 'bg-gray-100' : 'bg-white'
        }`}
      >
        <Input
          {..._props}
          type={'text'}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          className="grow text-[16px] leading-[1.5] tracking-[-0.32px] text-black-100 disabled:bg-gray-100 disabled:text-gray-700"
          onFocus={e => {
            setIsFocus(true)
            _props?.onFocus?.(e)
          }}
          onBlur={e => {
            setIsFocus(false)
            _props?.onBlur?.(e)
          }}
          disabled={disabled}
          maxLength={maxLength}
        />

        {maxLength != null && !disabled && (
          <div>
            <span className="mr-[2px] text-[14px] leading-[1.43] tracking-[-0.28px] text-primary-100">
              {value?.length ?? 0}
            </span>
            <span className="text-[14px] leading-[1.43] tracking-[-0.28px] text-gray-600">
              /{maxLength}
            </span>
          </div>
        )}

        {image != null && (
          <Column className="ml-[12px] mr-[-4px]">
            {image === '' ? (
              <FileInput
                ref={fileRef}
                onChangeFile={file => {
                  setImage?.(file.url)
                }}
              >
                <button
                  className="group/photo_btn flex h-[40px] w-[40px] items-center justify-center rounded-[8px] bg-gray-100"
                  onClick={onClickFile}
                  disabled={disabled}
                >
                  <PhotoIcon
                    className={`${
                      !disabled
                        ? 'group-hover/photo_btn:fill-black-100 group-active/photo_btn:fill-primary-100'
                        : ''
                    }`}
                  />
                </button>
              </FileInput>
            ) : (
              <Column className="group/vote_option_image relative h-[40px] w-[40px] ">
                <CoverFitImage
                  className="h-full w-full rounded-[8px]"
                  src={image}
                />
                <button
                  className={`absolute right-[4px] top-[4px] hidden h-[16px] w-[16px] items-center justify-center rounded-[50%] bg-black-0/[0.2] ${
                    disabled ? '' : 'group-hover/vote_option_image:flex'
                  }`}
                  onClick={() => {
                    setImage?.('')
                  }}
                  disabled={disabled}
                >
                  <CloseIcon />
                </button>
              </Column>
            )}
          </Column>
        )}
      </div>

      {errorMessages !== '' && (
        <span className="mt-[4px] text-[13px] leading-[1.38] tracking-[-0.26px] text-error-100">
          {errorMessages}
        </span>
      )}
    </Column>
  )
}

export default VoteInput
