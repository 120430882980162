import React from 'react'

import { VoteInterface } from '@api/voteApi'
import superClubProfileSource from '@assets/images/superclub-profile.png'
import Column from '@components/common/Column'
import Row from '@components/common/Row'
import CoverFitImage from '@components/image/CoverFitImage'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import { dateFormatter } from 'utils/utils'

interface VoteItemProps {
  vote: VoteInterface
}

const VoteItem: React.FC<VoteItemProps> = props => {
  const { vote } = props
  const navigate = useNavigate()

  const isAlreadyVoted = vote.vote_count > 0
  const isVotingAndHasExpiryDate =
    vote.status === 'VOTING' && vote.expiry_date != null
  const isEnded = vote.status === 'ENDED' && vote.ended_at != null

  return (
    <>
      <Column
        className="ml-[-24px] w-[calc(100%+48px)] cursor-pointer gap-[12px] bg-white px-[24px] py-[12px] hover:bg-gray-100"
        onClick={() => {
          if (vote.channel_url != null) {
            navigate(`/vote/${vote.id}/chat`)
          } else if (vote.club_id != null) {
            navigate(`/vote/${vote.id}/club`)
          } else {
            navigate(`/vote/${vote.id}`)
          }
        }}
      >
        <Row className="items-center justify-between">
          <Row className="gap-[12px]">
            <CoverFitImage
              src={
                vote.user != null
                  ? vote.user?.profile_image_url
                  : superClubProfileSource
              }
              className="h-[40px] w-[40px] rounded-full"
            />

            <Column className="gap-[2px]">
              <span className="text-[14px] font-semibold leading-[1.43] tracking-[-0.28px] text-black-200">
                {vote.user != null ? vote.user.username : 'Jerry'}
              </span>
              <span className="text-[13px] leading-[1.38] tracking-[-0.26px] text-gray-500">
                {dateFormatter(dayjs(vote.created))}
              </span>
            </Column>
          </Row>

          <Row className="items-center">
            {isAlreadyVoted && (
              <span className="text-[13px] leading-[1.38] tracking-[-0.26px] text-gray-500">
                <span className="text-primary-100">{vote.vote_count} </span>
                voter
              </span>
            )}

            {isAlreadyVoted && (isVotingAndHasExpiryDate || isEnded) && (
              <div className="mx-[8px] h-[12px] w-[1px] bg-gray-300" />
            )}

            {isVotingAndHasExpiryDate && (
              <span className="text-[13px] leading-[1.38] tracking-[-0.26px] text-gray-500">
                Ends in{' '}
                {dateFormatter(dayjs(vote.expiry_date)).replace('ago', '')}
              </span>
            )}

            {isEnded && (
              <span className="text-[13px] leading-[1.38] tracking-[-0.26px] text-gray-500">
                Ended {dateFormatter(dayjs(vote.ended_at))}
              </span>
            )}
          </Row>
        </Row>

        <span className="break-words text-[16px] font-medium leading-[1.5] tracking-[-0.32px] text-black-200">
          {vote.title}
        </span>
      </Column>
      <Column className="h-[1px] bg-gray-200" />
    </>
  )
}

export default VoteItem
