import axios, { AxiosError, InternalAxiosRequestConfig } from 'axios'

// 기본 인스턴스 생성
const instance = axios.create({
  // baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 10000,
  headers: { 'Content-Type': 'application/json' }
})

// 요청 인터셉터: 헤더에 토큰 추가
instance.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    // 여기에 인증 토큰을 설정할 수 있습니다. 예를 들면,
    const token = localStorage.getItem('accessToken')
    // console.log(localStorage.key(0))
    // console.log(token)
    if (token !== null) {
      config.headers.Authorization = `Bearer ${token}`
    }

    // else if (process.env.NODE_ENV === 'development') {
    //   config.headers.Authorization = `Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY2NvdW50X2lkIjo2NzUsImlhdCI6MTY4NTUzNzM2MywiZXhwIjoxNjg4MTI5MzYzfQ.La1cf9Vpkyd1TcJTbX8ZOElaBUjYWDWoBH_6oQ5IYGI2WGuUye44vbu9LQ25j_sedoK58C-5sZMGPTwVlrrW6EKJ1UoEGzuhlDNUFVlthTUWLlUYXtOBmh8OYAouFsaI7Q9zLXIb4YQEPlOggXOfIhZtFGu4ZHHt3lypjFRr9JQ`
    // }
    return config
  },
  async (error: AxiosError) => {
    return await Promise.reject(error)
  }
)

// // 응답 인터셉터: 응답 처리 및 오류 처리
// instance.interceptors.response.use(
//   (response: AxiosResponse) => {
//     // // 2xx 상태 코드인 경우 응답 반환
//     // if (response.status >= 200 && response.status < 300) {
//     //   return response;
//     // }
//     return response
//   },
//   (error: AxiosError) => {
//     // 네트워크 오류 처리
//     if (error.response == null) {
//       console.error('네트워크 오류가 발생했습니다.')
//       return
//     }

//     // 여기에서 여러 오류 상황을 처리할 수 있습니다.

//     // 인증 오류 처리 예시
//     if (error.response.status === STATUS_CODES.UNAUTHORIZED) {
//       console.error('인증 오류가 발생했습니다.')
//       // 인증 실패 시 로그인 페이지로 리다이렉트하거나 토큰 갱신 등의 로직을 구현할 수 있습니다.
//     }

//     // 404 오류 처리 예시
//     if (error.response.status === STATUS_CODES.NOT_FOUND) {
//       console.error('요청한 리소스를 찾을 수 없습니다.')
//       // 404 페이지로 리다이렉트하거나 사용자에게 안내할 수 있는 메시지를 표시할 수 있습니다.
//     }

//     // 5xx 오류 처리 예시
//     if (error.response.status >= 500) {
//       console.error('서버 오류가 발생했습니다.')
//       // 사용자에게 서버 오류에 대해 안내할 수 있는 메시지를 표시할 수 있습니다.
//     }

//     // // 기타 오류 처리
//     // // 예를 들어, 특정 오류 코드에 대한 추가 처리가 필요한 경우 다음과 같이 구현할 수 있습니다.
//     // if (error.response.status === STATUS_CODES.CUSTOM_ERROR) {
//     //   console.error("사용자 정의 오류 처리");
//     //   // 사용자 정의 오류에 대한 처리 로직을 구현할 수 있습니다.
//     // }
//   }
// )

export default instance
